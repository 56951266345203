import React, { Component } from 'react'
import Lottie from 'react-lottie'
import * as animationData from './FET_loading.json'

export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <div className="d-flex w-100 h-100 flex-align-center justify-center">
      <img className='loading-animation' src="/resources/images/loading.svg" alt="loading"/>
      {/* <Lottie options={defaultOptions}
        height={90}
        width={90}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused} /> */}
    </div>
  }
}
