export const AboutUsMock = {
  cbuLink: '//www.fetnet.net',
  ebuLink: '/ebu/index',
  logoLink: '/',
  loginlink: '#',
  lang: 'zh-TW',
  switch: {
    on: '商務',
    off: '個人',
  },
  logo: '/resources/common/images/fetnet-logo.png',
  aboutLink: '關於遠傳',
  keywordInput: '想要找什麼？',
  footerLink: ['聯絡客服', '網站地圖'],
  searchHelpers: [
    {
      link: '#',
      image: '/resources/corp/images/ic-80-cbu-contract-inquiry@2x.png',
      text: '查詢職缺',
    },
    {
      link: '/ebu/help-center?tab=ebu',
      image: '/resources/common/images/help-center.png',
      text: '幫助中心',
    },
    {
      link: '#',
      image: '/resources/common/images/download-catalog.png',
      text: '公司年報',
    },
  ],
  keywords: [
    {
      text: '職缺',
    },
    {
      text: '公司年報',
    },
    {
      text: '財務報告',
    },
    {
      text: '投資人活動',
    },
    {
      text: '股價',
    },
    {
      text: '最新消息',
    },
    {
      text: '線上填寫履歷',
    },
    {
      text: '菁英計畫',
    },
    {
      text: '企業參訪',
    },
    {
      text: '營運資料報告',
    },
  ],
  notLoginMenu: [
    [{ link: '/', text: '登入/註冊' }],
    [{ link: '/', text: '個人專區' }],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [{ link: '/', text: '帳戶管理' }],
  ],
  rentLoginMenu: [
    [
      { link: '/', text: '個人專區' },
      { link: '/', text: '線上繳費' },
      { link: '/', text: '帳單查詢' },
      { link: '/', text: '合約查詢' },
    ],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [
      { link: '/', text: '帳戶管理' },
      { link: '/', text: '登出' },
    ],
  ],
  prepaidLoginMenu: [
    [
      { link: '/', text: '個人專區' },
      { link: '/', text: '語音儲值' },
      { link: '/', text: '網路儲值' },
      { link: '/', text: '餘額/上網/到期日查詢' },
    ],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [
      { link: '/', text: '帳戶管理' },
      { link: '/', text: '登出' },
    ],
  ],
  noMsisdnLoginMenu: [
    [{ link: '/', text: '個人專區' }],
    [
      { link: '/', text: '我的訂單' },
      { link: '/', text: 'Happy Go 優惠' },
    ],
    [{ link: '/', text: '帳戶管理' }],
  ],
  listMaxLength: 4,
  mobileMenu: {
    content: {
      menu: [
        {
          title: '關於我們',
          list: [
            { text: '公司介紹', link: '' },
            { text: '品牌理念', link: '' },
            { text: '公司組織', link: '' },
            { text: '經營團隊', link: '' },
            { text: '重要紀事', link: '' },
            { text: '子公司/關係企業', link: '' },
            { text: '營業規章及服務契約', link: '' },
          ],
        },
        {
          title: '企業社會責任',
          list: [
            { text: '企業社會責任管理', link: '' },
            { text: '公司治理', link: '' },
            { text: '卓越服務', link: '' },
            { text: '利害關係人專區', link: '' },
            { text: '數位創新', link: '' },
            { text: '樂業職場', link: '' },
            { text: '社會共融', link: '' },
            { text: '環境永續', link: '' },
            { text: '價值共創', link: '' },
            { text: '影音全記錄', link: '' },
            { text: '報告書下載', link: '' },
          ],
        },
        {
          title: '投資人關係',
          list: [
            { text: '財務資訊', link: '' },
            { text: '公司年報', link: '' },
            { text: '投資人行事曆', link: '' },
            { text: '股東專欄', link: '' },
            { text: '投資人常見問題', link: '' },
          ],
        },
        {
          title: '最新消息',
          link: '/help-center/terms-of-service/privacy',
        },
        {
          title: '菁英招募',
          link: '/help-center/terms-of-service/privacy',
        },
      ],
      // moreCase: '/ebu/performance',
    },
    promotion: {
      image: {
        md: '/resources/ebu/images/ebu-medium-ad-01-600x260.jpg',
        sm: '/resources/ebu/images/ebu-medium-ad-01-600x260.jpg',
      },
      title: '遠傳企業Office 365 輕享方案',
      content: '最殺的價格、超彈性的付費、完整雲端行動辦公 遠傳一次幫你搞定！',
      action: {
        link: '#',
        text: '了解 Office 365 輕享',
      },
    },
  },
  desktopMenu: [
    {
      text: '關於我們',
      layout: 'list-2',
      content: {
        menu: [
          {
            title: '我們的初衷',
            list: [
              { link: '/', text: '公司介紹' },
              { link: '/', text: '品牌理念' },
            ],
          },
          {
            title: '我們的團隊',
            list: [
              { link: '#', text: '公司組織' },
              { link: '#', text: '經營團隊' },
              { link: '#', text: '重要紀事' },
              { link: '#', text: '子公司/關係企業' },
              { link: '#', text: '營業規章' },
            ],
          },
        ],

        commercial: {
          image: {
            md: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg',
            sm: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg',
          },
          title: '慶賀遠傳榮獲連鎖業奧斯卡獎！',
          content: '參選優良店長資格審查全數通過，成為同業最大贏家！',
          action: {
            text: '了解更多',
            link: '#',
          },
        },
      },
    },
    {
      text: '企業社會責任',
      layout: 'list-2',
      content: {
        menu: [
          {
            title: '企業承諾',
            list: [
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                text: '企業社會責任管理',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/mvpn/mvpn-intro.html',
                text: '公司治理',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/wvpn.html',
                text: '卓越服務',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/fet-uc/fet-uc-intro.html',
                text: '利害關係人專區',
              },
            ],
          },
          {
            title: '關注焦點',
            list: [
              {
                link: '/content/ebu/tw/product/broadband_product/broadband.html',
                text: '數位創新',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/voice.html',
                text: '樂業職場',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/voice.html',
                text: '社會共融',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                text: '環境永續',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/data-circuit.html',
                text: '價值共創',
              },
            ],
          },
          {
            title: '實踐成果',
            list: [
              {
                link: '/content/ebu/tw/product/broadband_product/broadband.html',
                text: '影音全記錄',
              },
              {
                link: '/content/ebu/tw/product/broadband_product/voice.html',
                text: '報告書下載',
              },
            ],
          },
        ],

        commercial: {
          image: {
            md: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg',
            sm: '/resources/cbu/life-circle-curation/images/sidebar-promotion-help-center.jpg',
          },
          title: '翻轉教育 讓愛遠傳',
          content: '以數位教學讓學童在玩樂中學習',
          action: {
            text: '觀看影片',
            link: '#',
          },
        },
      },
    },
    {
      text: '投資人關係',
      layout: 'list-2',
      content: {
        menu: [
          {
            title: '經營報告',
            list: [
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                text: '財務資訊',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/mvpn/mvpn-intro.html',
                text: '公司年報',
              },
            ],
          },
          {
            title: '公開資訊',
            list: [
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                text: '投資人行事曆',
              },
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/mvpn/mvpn-intro.html',
                text: '股東專欄',
              },
            ],
          },
          {
            title: '投資人問與答',
            list: [
              {
                link: '/content/ebu/tw/product/mobile_product/mobile_product-l3/volte/volte-vowifi-intro.html',
                text: '投資人常見問題',
              },
            ],
          },
        ],
      },
    },
    {
      text: '最新消息',
      link: '/help-center/terms-of-service/privacy',
    },
    {
      text: '菁英招募',
      link: '/help-center/terms-of-service/privacy',
    },
    {
      text: '5G',
      link: '/help-center/terms-of-service/privacy',
    },
  ],
};

export const hotword = [
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
  {
    text: '遠傳物聯網',
    link: '/help-center/search?keyword=遠傳物聯網',
  },
];

export const shortcut = [
  {
    link: '#',
    image: '/resources/cbu/help-center/images/store.svg',
    text: '找門市',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/change-number.svg',
    text: '預約門市換號',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/news.svg',
    text: '服務公告',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/search-number.svg',
    text: '門號查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/search-doc.svg',
    text: '保固查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/net.svg',
    text: '網路涵蓋率',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/report.svg',
    text: '網路預約報修',
  },
  {
    link: '#',
    image: '/resources/cbu/help-center/images/download.svg',
    text: '操作手冊下載',
  },
];

export const searchCard = {
  title: '想查詢什麼?',
  cards: [
    {
      title: '帳單/繳款/合約',
      description: '有關合約、帳單明細、各式繳款與儲值說明',
      link: '/help-center',
      linkMb: '/',
    },
    {
      title: '網路購物與商品',
      description: '有關商品說明、線上購買、寄送及售後服務等事項',
      link: '#',
      linkMb: '#',
    },
    {
      title: '遠傳生活圈',
      description: '有關遠傳數位加值服務、HAPPYGO和遠傳幣累積點數的常見問題與說明',
      link: '#',
      linkMb: '#',
    },
    {
      title: '月租型',
      description: '有關遠傳月租型方案說明',
      link: '#',
      linkMb: '#',
    },
  ],
  more: {
    text: '看更多',
    link: '#',
  },
};
