import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../Button';
import { Grid } from '@material-ui/core';

import Link from '../../Link';
import Dropdown from '../../Dropdown';
import ResponseCollapse from '../../collapse/ResponseCollapse';

import { FooterMock } from '../../../mock/Footer';

const Footer = props => {
  const [selectLang] = React.useState(
    props.lang ? { ...props.lang } : { value: '/ebu/index', text: '繁體中文' }
  );
  const [footerType, setFooterType] = React.useState('default');
  const [mock, setMock] = React.useState(FooterMock);
  const history = useHistory();
  const location = useLocation();
  const [adShow, setAdShow] = React.useState(true);

  React.useEffect(() => {
  });

  return (
    <footer className={`fui-footer pt-0`}>
      <div className='fui-container pt-4'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <h5 className='is-text-white is-text-light mt-md-6 mt-2 mb-md-8 mb-2'>我們用全新的用戶觀點與設計方法來完善更好的使用體驗，提升企業的競爭力。</h5>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className='d-flex flex-align-center justify-center'>
            <Button link="https://www.aja-creative.com/zh_tw/contact.html" className='is-text-white mb-md-0 mb-7' target="#" btnStyle='secondary' size="large">
              聯繫 AJA
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <img src={'/resources/images/aja-logo-style-1.svg'} alt='aja' className='aja' />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <p className='mt-0'>
              大予創意設計股份有限公司<br />
              AJA Creative Design Co., Ltd
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <small className='is-text-light'>
              10462 台北市中山區樂群二路187號6樓之2 (大直豐匯）<br />
              TEL +886-2-85029135<br />
              FAX +886-2-85029151<br />
              Mobile +886-922-083-876
            </small>
          </Grid>
        </Grid>
        <Grid container space={1} className='copyright'>
          <Grid item xs={12} sm={12} md={12}>
            <span className='text-mini is-text-gray100'>© Copyright 2020 AJA Creative Co., Ltd. All rights reserved.</span>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};

export default Footer;
