import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Link from '../components/Link';
const PlatformCard = () => {
  return (
    <div className="page-4">
      <div className="fui-container">
        <h4 className="is-text-white d-flex justify-center mt-0 mb-md-9 mb-6 is-text-regular">
          我們如何與遠傳團隊共創數位生活平台
        </h4>
        <VisibilitySensor partialVisibility={true}>
          {({ isVisible }) =>
            <div className="cards">
              <Link className="card is-bg-white" to="/">
                <p className="is-text-accent">
                  專案目標
                        </p>
                <h6>不只是電信，替未來生活提案</h6>
              </Link>
              <Link className="card is-bg-white" to="/Research-method">
                <p className="is-text-accent">
                  研究方法
                        </p>
                <h6>以研究洞察為起點，建立高效協作的溝通流程</h6>
              </Link>
              <Link className="card is-bg-white" to="/Architrcture">
                <p className="is-text-accent">
                  資訊架構
                        </p>
                <h6>從個人到商用，聚焦需求快速分流</h6>
              </Link>
              <Link className="card is-bg-white" to="/Promotion">
                <p className="is-text-accent">
                  情境式行銷
                        </p>
                <h6>活絡集團資源與跨售，數位服務客製化讓廣告更有感</h6>
              </Link>
              <Link className="card is-bg-white" to="/DesignSystem">
                <p className="is-text-accent">
                  識別系統
                        </p>
                <h6>打造品牌識別，推動向前的設計主張</h6>
              </Link>
            </div>
          }
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default PlatformCard;
