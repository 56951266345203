import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import ArrowLeftWhite from '../components/animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../components/animateArrow/ArrowRightWhite';
import RedArrow from '../components/animateArrow/RedArrow';
import VisibilitySensor from 'react-visibility-sensor';
import PlatformCard from '../components/PlatformCard'
import path1 from '../components/path/dash-path-1.svg'
import * as Mock from '../mock/CorpIndex';
import zIndex from '@material-ui/core/styles/zIndex';
import { debounce } from 'lodash'
class ResearchMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: window.innerWidth,
      mapIndex: 0,
      mapList: ['Moodboard', 'Photography', 'Color', 'Iconography', 'Typography', 'Pattern', 'Interactive'],
      isMobile: window.innerWidth < 960,
      sliderSetting: {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        draggable: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              variableWidth: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              variableWidth: true
            }
          }
        ],
      },
      sliderOne: [
        {
          image: '/resources/images/visual-practical-1-1@2x.png',
          alt: 'visual-practical-01'
        },
        {
          image: '/resources/images/visual-practical-1-2@2x.png',
          alt: 'visual-practical-02'
        },
        {
          image: '/resources/images/visual-practical-1-3@2x.png',
          alt: 'visual-practical-03'
        },
      ],
      sliderTwo: [
        {
          image: '/resources/images/visual-practical-2-1@2x.png',
          alt: 'visual-practical-01'
        },
        {
          image: '/resources/images/visual-practical-2-2@2x.png',
          alt: 'visual-practical-02'
        },
        {
          image: '/resources/images/visual-practical-2-3@2x.png',
          alt: 'visual-practical-03'
        },
      ],
      sliderThree: [
        {
          image: '/resources/images/visual-practical-3-1@2x.png',
          alt: 'visual-practical-01'
        },
        {
          image: '/resources/images/visual-practical-3-2@2x.png',
          alt: 'visual-practical-02'
        },
        {
          image: '/resources/images/visual-practical-3-3@2x.png',
          alt: 'visual-practical-03'
        }
      ],
      sliderFour: [
        {
          image: '/resources/images/visual-practical-4-1@2x.png',
          alt: 'visual-practical-01'
        },
        {
          image: '/resources/images/visual-practical-4-2@2x.png',
          alt: 'visual-practical-02'
        },
        {
          image: '/resources/images/visual-practical-4-3@2x.png',
          alt: 'visual-practical-03'
        }
      ],
      sliderFive: [
        {
          image: '/resources/images/visual-practical-5-1@2x.png',
          alt: 'visual-practical-01'
        },
        {
          image: '/resources/images/visual-practical-5-2@2x.png',
          alt: 'visual-practical-02'
        },
        {
          image: '/resources/images/visual-practical-5-3@2x.png',
          alt: 'visual-practical-03'
        },
      ],
      positionY: window.scrollY,
      y: 0
    }
  }
  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        width: window.innerWidth,
        isMobile: window.innerWidth < 960
      })
    });
    document.addEventListener('scroll', () => this.setState({
      positionY: window.scrollY,
      y: document.getElementsByClassName('masonry-block')[0].getBoundingClientRect()
    }), {
      capture: true,
      passive: true
    })
  }
  mapIndex(val) {
    this.setState({
      mapIndex: val
    })
  }
  getPosition() {
    let y = this.state.y;
    if (y.top - y.height < -200) {
      return 375 + ((y.top - y.height) / 3)
    } else {
      return 375
    }
  }
  getPositionNegative() {
    let y = this.state.y;
    if (y.top - y.height < -200) {
      return -(y.top - y.height) / 3
    } else {
      return 0
    }
  }
  active() {
    let y = this.state.y;
    if (y.top - y.height < -200) {
      return true
    } else {
      return false
    }
  }
  getMap() {
    let key = this.state.mapIndex
    switch (key) {
      case 0:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/design-system-slide-1@2x.jpg" alt="photography" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Moodboard</div>
              <div className="body">
                我們的數位視覺形象探索簡潔的設計語⾔並展現明亮且現代的視覺感受，利⽤動態與幾何元素呼應品牌識別， 表達持續向前的精神。
              </div>
            </Grid>
          </Grid>
        )
        break;

      case 1:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/photography.png" alt="photography" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Photography</div>
              <div className="body mb-6">
                個人用戶的影像展現出人們藉由科技的便利，感到愉悅且享受。投射用戶生活中的真實場景，照片們應該看起來自然，並帶有些許的故事性隱喻，在光線、物件的構圖下，傳遞溫暖和自在的意象。
                </div>
              <div className="body">
                企業用戶的影像要展現出全力投入帶來的熱忱和自信。從微型到大型，營造出我們都準備好的情緒，以專注與微笑的影像來彰顯可靠與知性。
                </div>
            </Grid>
          </Grid>
        )
      case 2:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/design-system-slide-3@2x.png" alt="color" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Color</div>
              <div className="body">
                科技的既定冰冷感因為豐富的數位⽣活顯得多采多姿。 除了我們使⽤的品牌主色，我們為個⼈⽤戶增添了不同的⾊相當作輔色。適度的在影像和裝飾性元素融入這些色彩，給予品牌繽紛和多元的氛圍，傳遞因數位而豐富生活的視覺語言。 在企業⽤⼾則增添了灰藍⾊調的⾊彩，賦予專業、精明 和沈穩的形象。
                </div>
            </Grid>
          </Grid>
        )
      case 3:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/design-system-slide-4@2x.jpg" alt="Iconography" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Iconography</div>
              <div className="body mb-6">
                我們使⽤的圖標系統來自對品牌識別的呼應及延伸。 從⾓度、對比、正負空間運⽤，基於現代和辨識性原則，保持圖標的簡潔和一致性，適度輔助在⽂字的閱讀與空間，巧妙的點綴，為整體視覺提供了了穩定性和結構性。<br />
                  不同情境區分成兩種類型與使⽤時機：
                </div>
              <div className="body">
                指⽰：引導⽤戶操作和前往<br />
                  說明：⽂字外的輔助來加深印象
                </div>
            </Grid>
          </Grid>
        )
      case 4:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/design-system-slide-5@2x.jpg" alt="Typography" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Typography</div>
              <div className="body">
                依循快速便捷的設計目標，我們使用的字型保有易於獲得和輕量化特性，捨棄加載字型方式，採用裝置作業系統預設字型優先讀取方式，並且在中文及歐文顯示上秉持高度識別和易於閱讀的原則，所以採用無襯線的現代簡潔風格，字體上則呈現多款重量感以滿足各種場合需要的語氣效果。
                </div>
            </Grid>
          </Grid>
        )
      case 5:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/design-system-slide-6@2x.jpg" alt="Pattern" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Pattern</div>
              <div className="body">
                圖案的運用是對品牌識別的想像與擴展。從特定角度的擷取、旋轉、縮放和重複再重複，搭配主輔色的組合，適度的活用在頁面的組件背景或設計細節上，形成品牌獨特性的襯托元素，在簡潔的空間佈局之餘，加深品牌的辨識度。
                </div>
            </Grid>
          </Grid>
        )
      case 6:
        return (
          <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
            <Grid item xs={12} md={8} className=''>
              <img className='w-100 d-flex' src="/resources/images/design-system-slide-7@2x.jpg" alt="Interactive" />
            </Grid>
            <Grid item xs={12} md={4} className=''>
              <div className='mb-4 photography-head'>Interactive</div>
              <div className="body">
                我們在互動上反映出對品牌指標性圖像的啟發，從進場、轉場的動作或使用者在操作上的探索，處處創造細微的驚喜，在整體運動方向和節奏上皆保有視覺和互動回饋的一致性，為品牌的數位體驗增添生命力。
                </div>
            </Grid>
          </Grid>
        )

      default:
        break;
    }
  }
  render() {
    return (
      <main className='research-method design-system'>
        <div>
          <div className='animation-wrapper' style={{ position: 'absolute', height: 557 }}>
            <div className="top-animation" style={{ zoom: this.state.width < 960 ? this.state.width / 1000 : 1 }}>
              <div className="triangle-bg"></div>
              <img className='bg-1' src="/resources/images/index-animation/banner-chapter-1-triangle-1.svg" alt="bg" />
              {/* <img className='bg-2' src="/resources/images/index-animation/banner-chapter-1-visual-2@2x.png" alt="bg-1" /> */}
              <img className='bg-3' src="/resources/images/index-animation/banner-chapter-1-visual-3.svg" alt="bg-2" />
              <img className='bg-4' src="/resources/images/index-animation/banner_chapter1_visual-4@2x.png" alt="bg-3" />
              <img className='bg-5' src="/resources/images/index-animation/banner-chapter-1-triangle-group.svg" alt="bg-4" />
            </div>
          </div>
          <div className='stickyroll-content'>
            <div className="top" >
              <div className="left" />
              <div className="right" />
            </div>
            <div className="research-method-page-1">
              <div className="align-center wrapper">
                <div className="body is-text-bold mb-md-3 mb-0">
                  設計系統
                </div>
                <h2 className='mt-0'>打造品牌識別，推動向前的設計主張</h2>
              </div>
            </div>
            <div className="research-method-page-2">
              <div className="fui-container design-icons">
                <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
                  <Grid item xs={12} md={4}>
                    <div className='is-text-white content-title is-text-bold'>Forward 帶著你前進探索，享受生活的驚喜</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h6 className='is-text-gray is-text-regular mb-0 mt-1'>以豐富多元、前進探索、驚喜感動為基礎，優化各個使用者接觸到的服務。將遠傳指標圖型加以發揚及延伸，箭頭充分使用在網站的各處，指引你前往精彩的數位生活。飄逸的點綴元素及繽紛色彩是對精采生活的隱喻，處處展現了充滿個人化的設計，描繪出對電信商未來應有樣貌的想像。</h6>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="design-icons-title align-center is-text-coral70 is-text-bold">依循品牌精神，從感性到理性的轉化，制定了以下的視覺設計目標</div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="list">
                      <img src="/resources/images/forward-element-1.svg" alt="heart" />
                      <h4 className="is-text-coral70 is-text-bold">溫暖貼心</h4>
                      <div className="body is-text-coral70 is-text-regular">從個人需求出發，以情境與脈絡故事作為契機。</div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="list">
                      <img src="/resources/images/forward-element-2.svg" alt="heart" />
                      <h4 className="is-text-coral70 is-text-bold">快速便捷</h4>
                      <div className="body is-text-coral70 is-text-regular">去除繁瑣，用戶能輕鬆理解資訊並做出決策。</div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="list">
                      <img src="/resources/images/forward-element-3.svg" alt="heart" />
                      <h4 className="is-text-coral70 is-text-bold">拓展體驗</h4>
                      <div className="body is-text-coral70 is-text-regular">協助用戶探索新事物，專注於提升生活品質。</div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="list">
                      <img src="/resources/images/forward-element-4.svg" alt="heart" />
                      <h4 className="is-text-coral70 is-text-bold">愜意快活</h4>
                      <div className="body is-text-coral70 is-text-regular">日常中的不同階段，創造生活愉悅的驚喜。</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="proposal">
              <div className="fui-container">
                <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
                  <div className="icon-design-system">
                    <img className='' src="/resources/images/design-system.svg" alt="design system" />
                  </div>
                  <Grid item xs={12} md={5}>
                    <div className='is-text-darkgray50 content-title is-text-bold'>設計系統</div>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <h6 className='is-text-darkgray50 is-text-regular mb-0 mt-0'>我們建立的數位設計系統由這七大視覺語言構成，從影像到結構性元素，搭配輔助、提示和引導，讓產品更具獨特性。</h6>
                  </Grid>
                  <ArrowLeftWhite color="gray" />
                  <div className="map-list">
                    {this.state.mapList.map((item, index) => {
                      return (
                        <button key={index + item} className={this.state.mapIndex === index ? 'active' : ''} onClick={() => this.mapIndex(index)}>{item}</button>
                      )
                    })}
                  </div>
                </Grid>
                {this.getMap()}

              </div>
            </div>
            <div className="extendable">
              <div className="fui-container">
                <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
                  <Grid item xs={12} md={4}>
                    <div className='is-text-white content-title is-text-bold'>視覺延伸使用規範與開發</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h6 className='is-text-gray is-text-regular mb-0 mt-1'>如何執行品牌設計，具體落實考慮到可以運營性。</h6>
                  </Grid>
                </Grid>
              </div>
              <div className="fui-container">
                <div className="brand-slider mt-md-8 pt-md-7 pt-0">
                  <ArrowRightWhite />
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={7} className="slider-wrapper">
                      <Slider className='branding' {...this.state.sliderSetting}>
                        {this.state.sliderOne.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                    <Grid item xs={12} md={5} className="title d-md-flex flex-column" style={{ zIndex: 0 }}>
                      <p className='d-flex flex-align-center is-text-darkgray50 mb-md-1 mb-0 mt-md-0 mt-6'>
                        <img style={{ width: 20, height: 14 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                        圖標系統
                      </p>
                      <h4 className="is-font-bold mt-0 mb-md-6 is-text-white mb-0">
                        依照不同層級需求，在互動引導及視覺表現間取得平衡
                      </h4>
                    </Grid>
                  </Grid>

                </div>
              </div>
              <div className="fui-container">
                <div className="brand-slider is-left mt-md-8 pt-md-7 pt-0">
                  <Grid container spacing={5}>
                    <ArrowLeftWhite />
                    <Grid item xs={12} md={5} className="title d-md-flex flex-column" style={{ zIndex: 0 }}>
                      <p className='d-flex flex-align-center is-text-darkgray50 mb-md-1 mb-0 mt-md-0 mt-6'>
                        <img style={{ width: 20, height: 14 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                        廣告系統規範制定
                      </p>
                      <h4 className="is-font-bold mt-0 mb-md-6 is-text-white mb-0">
                        統整維運需求，維持橫幅廣告的視覺一制性
                      </h4>
                    </Grid>
                    <Grid item xs={12} md={7} className="slider-wrapper">
                      <Slider className='branding' {...this.state.sliderSetting}>
                        {this.state.sliderTwo.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="fui-container">
                <div className="brand-slider mt-md-8 pt-md-7 pt-0">
                  <Grid container spacing={5}>
                    <ArrowRightWhite />
                    <Grid item xs={12} md={7} className="slider-wrapper">
                      <Slider className='branding' {...this.state.sliderSetting}>
                        {this.state.sliderThree.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                    <Grid item xs={12} md={5} className="title d-md-flex flex-column" style={{ zIndex: 0 }}>
                      <p className='d-flex flex-align-center is-text-darkgray50 mb-md-1 mb-0 mt-md-0 mt-6'>
                        <img style={{ width: 20, height: 14 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                        牌卡系統
                      </p>
                      <h4 className="is-font-bold mt-0 mb-md-6 is-text-white mb-0">
                        優化訊息呈現，降低使用者吸收資訊的負擔
                      </h4>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="fui-container">
                <div className="brand-slider is-left mt-md-8 pt-md-7 pt-0">
                  <Grid container spacing={5}>
                    <ArrowLeftWhite />
                    <Grid item xs={12} md={5} className="title d-md-flex flex-column" style={{ zIndex: 0 }}>
                      <p className='d-flex flex-align-center is-text-darkgray50 mb-md-1 mb-0 mt-md-0 mt-6'>
                        <img style={{ width: 20, height: 14 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                        圖案設計
                      </p>
                      <h4 className="is-font-bold mt-0 mb-md-6 is-text-white mb-0">
                        透過獨特性的襯托元素，加深品牌的辨識度
                      </h4>
                    </Grid>
                    <Grid item xs={12} md={7} className="slider-wrapper">
                      <Slider className='branding' {...this.state.sliderSetting}>
                        {this.state.sliderFour.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="fui-container">
                <div className="brand-slider mt-md-8 pt-md-7 pt-0">
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={7} className="slider-wrapper">
                      <Slider className='branding' {...this.state.sliderSetting}>
                        {this.state.sliderFive.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                    <Grid item xs={12} md={5} className="title d-md-flex flex-column" style={{ zIndex: 0 }}>
                      <p className='d-flex flex-align-center is-text-darkgray50 mb-md-1 mb-0 mt-md-0 mt-6'>
                        <img style={{ width: 20, height: 14 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                        插畫系統
                      </p>
                      <h4 className="is-font-bold mt-0 mb-md-6 is-text-white mb-0">
                        增強界面的美學和情感吸引力，同時對資訊的閱讀加以輔助
                      </h4>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="masonry">
              <div className="container">
                <Grid container spacing={5}>
                  <Grid item xs={12} md={4} className='pl-md-4'>
                    <div className='is-text-darkgray50 masonry-title is-text-bold'>靈活性設計，依需求快速組合、變化</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h6 className='is-text-darkgray50 is-text-regular mb-0 mt-1'>UX使用範例 / 模組組合示範畫面呈現模組化價值。</h6>
                  </Grid>
                </Grid>
              </div>
              {/* <img className="masonry-bg" src="/resources/images/masonry-bg.png" alt="bg" /> */}
              <div className="masonry-block">
                <div className="column" style={{ backgroundPositionY: this.getPositionNegative(), backgroundImage: 'url(/resources/images/tile-01.png)' }} />
                <div className="column" style={{ backgroundPositionY: this.getPosition(), backgroundImage: 'url(/resources/images/tile-02.png)' }} />
                <div className="column" style={{ backgroundPositionY: this.getPositionNegative(), backgroundImage: 'url(/resources/images/tile-03.png)' }} />
                <div className="column" style={{ backgroundPositionY: this.getPosition(), backgroundImage: 'url(/resources/images/tile-04.png)' }} />
                <div className="column" style={{ backgroundPositionY: this.getPositionNegative(), backgroundImage: 'url(/resources/images/tile-05.png)' }} />
              </div>
            </div>
            <PlatformCard />
          </div>
        </div>

      </main >
    )
  }
}
export default ResearchMethod