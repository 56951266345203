import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import * as Action from './stores/actions';
import { connect } from 'react-redux';

import Loader from './components/Loader';
import Header from './components/partials/header/Default';
import Footer from './components/partials/footer/Default';

// loading animation
import Index from './pages/Index';
import ResearchMethod from './pages/ResearchMethod';
import Architrcture from './pages/Architrcture';
import Promotion from './pages/Promotion'
import DesignSystem from './pages/DesignSystem'

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// window.history.pushState('', null, './');
let path = '';
// 如果按瀏覽器上一頁，則 reload 畫面
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', function () {
    window.location.reload(true);
  });

  path = window.location.pathname;
}

const App = (state) => {
  const lazyLoad = (e) => {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  };

  useEffect(() => {
    document.addEventListener('lazybeforeunveil', lazyLoad);
    return () => {
      document.removeEventListener('lazybeforeunveil', lazyLoad);
    };
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Loader />
      <Header />
      <ParallaxProvider>
        <Switch>
          <Route path='/' exact component={Index} />
          <Route path='/Research-method' exact component={ResearchMethod} />
          <Route path='/Architrcture' exact component={Architrcture} />
          <Route path='/Promotion' exact component={Promotion} />
          <Route path='/DesignSystem' exact component={DesignSystem} />
        </Switch>
      </ParallaxProvider>
      <Footer adShow={true} />
    </Router>
  );
};

const ReduxExample = connect((state) => state, { ...Action })(App);

export default ReduxExample;
