import React from 'react';
import ArrowRightWhite from '../components/animateArrow/ArrowRightWhite';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import ArrowLeftWhite from '../components/animateArrow/ArrowLeftWhite';
import VisibilitySensor from 'react-visibility-sensor';
import path1 from '../components/path/dash-path-1.svg'
import * as Mock from '../mock/CorpIndex';
import zIndex from '@material-ui/core/styles/zIndex';
import PlatformCard from '../components/PlatformCard'
class ResearchMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: window.innerWidth,
      mapIndex: 0,
      mapList: ['首頁分類快捷', '主選單架構設計', '企業規模分流', '搜尋系統', '頁面層級定義'],
      isMobile: window.innerWidth < 960,
      activeSlide: 0,
      sliderSetting: {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        draggable: true,
        afterChange: current => this.setState({ activeSlide: current }),
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              variableWidth: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              variableWidth: true
            }
          }
        ],
      },
      sliderOne: [
        {
          image: '/resources/images/arch_information-slide1@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide2@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide3@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide4@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide5@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide6@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide7@2x.jpg',
          alt: '01'
        },
      ],
      sliderOneMb: [
        {
          image: '/resources/images/arch_information-slide1_m@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch_information-slide2_m@2x.jpg',
          alt: '02'
        },
        {
          image: '/resources/images/arch_information-slide3_m@2x.jpg',
          alt: '03'
        },
        {
          image: '/resources/images/arch_information-slide4_m@2x.jpg',
          alt: '04'
        },
        {
          image: '/resources/images/arch_information-slide5_m@2x.jpg',
          alt: '05'
        },
        {
          image: '/resources/images/arch_information-slide6_m@2x.jpg',
          alt: '06'
        },
        {
          image: '/resources/images/arch_information-slide7_m@2x.jpg',
          alt: '07'
        },
      ],
    }
  }
  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        width: window.innerWidth,
        isMobile: window.innerWidth < 960
      })
    })
  }
  mapIndex(val) {

    this.setState({
      mapIndex: val
    })

  }
  getTitle() {
    let i = this.state.activeSlide
    switch (true) {
      case i == 0:
        return '首頁'
      case i == 1:
        return '線上客服 - 艾瑪'
      case i == 2:
        return '搜尋'
      case i == 3:
        return '找商品'
      case i == 4:
        return '幫助中心'
      case i == 5:
        return '行銷'
      case i == 6:
        return '你可能會喜歡...'
      default:
        break;
    }
  }
  getContent() {
    let i = this.state.activeSlide;
    switch (true) {
      case i == 0:
        return '直白輕鬆的溝通，待客如待友，懂用戶的需要'
      case i == 1:
        return '整合單一入口，隨時快速諮詢'
      case i == 2:
        return '主動提供幫助，幫客戶多想一步的體貼支持'
      case i == 3:
        return '清楚的引導，總是知道下一步在哪'
      case i == 4:
        return '統整線上資源的幫助中心'
      case i == 5:
        return '貼心易懂的白話文，降低資訊溝通的落差'
      case i == 6:
        return '適時不強迫的個人化推薦'
      default:
        break;
    }
  }
  render() {
    return (
      <main className='research-method artichitecture'>
        <div>
          <div className='animation-wrapper' style={{ position: 'absolute', height: 557 }}>
            <div className="top-animation" style={{ zoom: this.state.width < 960 ? this.state.width / 1000 : 1 }}>
              <div className="triangle-bg"></div>
              <img className='bg-1' src="/resources/images/index-animation/banner-chapter-1-triangle-1.svg" alt="bg" />
              {/* <img className='bg-2' src="/resources/images/index-animation/banner-chapter-1-visual-2@2x.png" alt="bg-1" /> */}
              <img className='bg-3' src="/resources/images/index-animation/banner-chapter-1-visual-3.svg" alt="bg-2" />
              <img className='bg-4' src="/resources/images/index-animation/banner-chapter-1-visual-2.png" alt="bg-3" />
              <img className='bg-5' src="/resources/images/index-animation/banner-chapter-1-triangle-group.svg" alt="bg-4" />
            </div>
          </div>
          <div className='stickyroll-content'>
            <div className="top" >
              <div className="left" />
              <div className="right" />
            </div>
            <div className="research-method-page-1">
              <div className="align-center wrapper">
                <div className="body is-text-bold mb-md-3 mb-0">
                  資訊架構
                </div>
                <h2 className='mt-0'>從個人到商用，聚焦需求快速分流</h2>
              </div>
            </div>
            <div className="research-method-page-2">
              <div className="fui-container">
                <Grid container spacing={this.state.isMobile ? 4 : 6} className="content-block">
                  <Grid item xs={12} md={4}>
                    <div className='is-text-white content-title is-text-bold'>建構一致的貼心服務形象</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h5 className='is-text-white mt-0 mb-md-4'>親切好服務不打烊</h5>
                    <h6 className='is-text-gray is-text-regular mb-0 mt-md-3 mt-1'>從用戶調查中得知，客人喜歡實體門市中人與人的互動，有人情味又單一窗口處理到好使人安心。那我們如何轉化門市的溫暖到官網上，繼續呈現動人價值？</h6>
                  </Grid>
                </Grid>
                <ArrowRightWhite />
                <div className="brand-slider mt-md-8 pt-md-7 pt-0">
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={7} className="slider-wrapper">
                      <Slider className='branding' {...this.state.sliderSetting}>
                        {this.state.isMobile ? this.state.sliderOneMb.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        )) : this.state.sliderOne.map((card, idx) => (
                          <div key="idx" className='img'>
                            <img src={card.image} alt={card.alt} />
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                    <Grid item xs={12} md={5} className="title d-md-flex flex-column" style={{ zIndex: 0 }}>
                      <p className='d-flex flex-align-center is-text-darkgray50 mb-md-1 mb-0 mt-md-0 mt-6'>
                        <img style={{ width: 20, height: 14 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                        {this.getTitle()}
                      </p>
                      <h4 className="is-font-bold mt-0 mb-md-6 is-text-white mb-0">
                        {this.getContent()}
                      </h4>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="desktop-mobile-demo">
              <div className="fui-container">
                <Grid container spacing={this.state.isMobile ? 3 : 6}>
                  <Grid item xs={12} md={5}>
                    <div className='is-text-darkgray30 is-text-bold desktop-mobile-demo-title'>個人服務至上，依需求快速分流</div>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <h6 className='is-text-darkgray30 is-text-regular mt-1'>使用活潑生活化的溝通方式，與階段性活動促銷。</h6>
                  </Grid>
                  <div className="map-list">
                    {this.state.mapList.map((item, index) => {
                      return (
                        <button key={index + item} className={this.state.mapIndex === index ? 'active' : ''} onClick={() => this.mapIndex(index)}>{item}</button>
                      )
                    })}
                  </div>
                </Grid>
                {this.state.mapIndex === 0 ? <Grid item xs={12} md={12} className="mt-3 px-6">
                  <img className='d-md-flex d-none w-100' src="/resources/images/information-arch-feature-1.png" alt="desktop-mobile-demo" />
                  <img className='d-flex d-md-none w-100' src="/resources/images/information_arch_feature1_m@2x.png" alt="desktop-mobile-demo" />
                </Grid> : null}
                {this.state.mapIndex === 1 ? <Grid item xs={12} md={12} className="mt-3 px-6">
                  <img className='d-md-flex d-none w-100' src="/resources/images/information_arch_feature2@2x.png" alt="desktop-mobile-demo" />
                  <img className='d-flex d-md-none w-100' src="/resources/images/information_arch_feature2_m@2x.png" alt="desktop-mobile-demo" />
                </Grid> : null}
                {this.state.mapIndex === 2 ? <Grid item xs={12} md={12} className="mt-3 px-6">
                  <img className='d-md-flex d-none w-100' src="/resources/images/information_arch_feature3@2x.png" alt="desktop-mobile-demo" />
                  <img className='d-flex d-md-none w-100' src="/resources/images/information_arch_feature3_m@2x.png" alt="desktop-mobile-demo" />
                </Grid> : null}
                {this.state.mapIndex === 3 ? <Grid item xs={12} md={12} className="mt-3 px-6">
                  <img className='d-md-flex d-none w-100' src="/resources/images/information_arch_feature4@2x.png" alt="desktop-mobile-demo" />
                  <img className='d-flex d-md-none w-100' src="/resources/images/information_arch_feature4_m@2x.png" alt="desktop-mobile-demo" />
                </Grid> : null}
                {this.state.mapIndex === 4 ? <Grid item xs={12} md={12} className="mt-3 px-6">
                  <img className='d-md-flex d-none w-100' src="/resources/images/information_arch_feature5@2x.png" alt="desktop-mobile-demo" />
                  <img className='d-flex d-md-none w-100' src="/resources/images/information_arch_feature5_m@2x.png" alt="desktop-mobile-demo" />
                </Grid> : null}
              </div>
            </div>
            <div className="communication">
              <div className="fui-container">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <div className='communication-title is-text-bold'>善用推薦演算法，<br />更懂用戶的需求</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h6 className='is-text-darkgray30 is-text-regular mb-0 mt-1'>從數位開始客製化，提供個人貼標主題與推薦。</h6>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <VisibilitySensor offset={{ top: 300 }} partialVisibility={true}>
                    {({ isVisible }) =>
                      <div className={`disapear ${isVisible ? 'appear' : ''} align-center w-100`}>
                        <img className='px-md-0 px-2 w-100 mt-2 personalization-illustration' src="/resources/images/personalization-illustration.png" alt="personalization-illustration" />
                      </div>
                    }
                  </VisibilitySensor>
                </Grid>
              </div>
            </div>
            <PlatformCard />
          </div>
        </div>

      </main >
    )
  }
}
export default ResearchMethod