import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import ArrowLeftWhite from '../components/animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../components/animateArrow/ArrowRightWhite';
import RedArrow from '../components/animateArrow/RedArrow';
import VisibilitySensor from 'react-visibility-sensor';
import path1 from '../components/path/dash-path-1.svg'
import * as Mock from '../mock/CorpIndex';
import zIndex from '@material-ui/core/styles/zIndex';
import PlatformCard from '../components/PlatformCard'
class ResearchMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: window.innerWidth,
      mapIndex: 0,
      mapList: ['攜碼、續約', '繳費', '客服', '加值服務'],
      isMobile: window.innerWidth < 960
    }
  }
  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        width: window.innerWidth,
        isMobile: window.innerWidth < 960
      })
    })
  }
  mapIndex(val) {

    this.setState({
      mapIndex: val
    })

  }
  render() {
    return (
      <main className='research-method'>
        <div>
          <div className='animation-wrapper' style={{ position: 'absolute', height: 557 }}>
            <div className="top-animation" style={{ zoom: this.state.width < 960 ? this.state.width / 1000 : 1 }}>
              <div className="triangle-bg"></div>
              <img className='bg-1' src="/resources/images/index-animation/banner-chapter-1-triangle-1.svg" alt="bg" />
              {/* <img className='bg-2' src="/resources/images/index-animation/banner-chapter-1-visual-2@2x.png" alt="bg-1" /> */}
              <img className='bg-3' src="/resources/images/index-animation/banner-chapter-1-visual-3.svg" alt="bg-2" />
              <img className='bg-4' src="/resources/images/index-animation/banner-chapter-1-visual-1@2x.png" alt="bg-3" />
              <img className='bg-5' src="/resources/images/index-animation/banner-chapter-1-triangle-group.svg" alt="bg-4" />
            </div>
          </div>
          <div className='stickyroll-content'>
            <div className="top" >
              <div className="left" />
              <div className="right" />
            </div>
            <div className="research-method-page-1">
              <div className="align-center wrapper">
                <div className="body is-text-bold mb-md-3 mb-0">
                  研究方法
                </div>
                <h2 className='mt-0'>以顧客研究發現為起點，打造多單位協作的高效溝通</h2>
              </div>
            </div>
            <div className="research-method-page-2">
              <div className="fui-container">
                <div className="dotline-mb d-block d-md-none" />


                <Grid container spacing={this.state.isMobile ? 0 : 8} className="pt-4 pt-md-0">
                  <VisibilitySensor offset={{ top: 300 }} partialVisibility="top" partialVisibility={true}>
                    {({ isVisible }) =>
                      <div className={`disapear ${isVisible ? 'appear' : ''} dotline dotline-01`}>
                        <svg className="path-svg" id="path-1" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.63 259.67">
                          <title>dash-path-1</title>
                          <path className="path" d="M366,2.5c25.57,69.65-55.32,139.77-175.5,174-47.16,25.57-76,53.53-91.4,81.67" transform="translate(-97.1 -0.5)" />
                          <path className="dashed" d="M366,2.5c25.57,69.65-55.32,139.77-175.5,174-47.16,25.57-76,53.53-91.4,81.67" transform="translate(-97.1 -0.5)" />
                        </svg>
                      </div>
                    }
                  </VisibilitySensor>
                  <Grid item xs={6} md={4} className='position-relative'>
                    <img className='w-100' src="/resources/images/workflow-img-1@2x.jpg" alt="flow-1" />
                  </Grid>
                  <Grid item xs={6} md={8} className='step-01'>
                    <img src="/resources/images/num-01.svg" alt="01" />
                    <div className="content">
                      <h5 className="is-text-white">
                        經營團隊訪談
                    </h5>
                      <div className="body is-text-gray">
                        邀請經營團隊分享願景、目標、過往經驗，釐清產業脈絡，聚焦內部需求與待解議題。
                      </div>
                    </div>
                  </Grid>
                  <ArrowLeftWhite />
                </Grid>
                <Grid container spacing={this.state.isMobile ? 0 : 8}>
                  <ArrowRightWhite />
                  <VisibilitySensor partialVisibility="top" offset={{ top: 300 }} partialVisibility={true}>
                    {({ isVisible }) =>
                      <div className={`disapear ${isVisible ? 'appear' : ''} dotline`}>
                        <svg className='path-svg path-2' data-name="Layer 2" xmlns="http://www.w3.org/2000/svg">
                          <title>dash-path-1</title>
                          <path className="path" d="M381.6,127.8c-22.7,71,33.3,139.6,90.9,170c144,76,325.3,19.3,471,150c28.5,34.8,60.8,84.2,60.9,138" />
                          <path className="dashed" d="M381.6,127.8c-22.7,71,33.3,139.6,90.9,170c144,76,325.3,19.3,471,150c28.5,34.8,60.8,84.2,60.9,138" />
                        </svg>
                      </div>
                    }
                  </VisibilitySensor>
                  <Grid item xs={6} md={8} className='step-02'>
                    <img src="/resources/images/num-02.svg" alt="02" />
                    <h5 className="is-text-white">
                      設計討論
                    </h5>
                    <div className="body is-text-gray">
                      與內部團隊分享現況與痛點，尋找設計機會點。
                  </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img className='w-100' src="/resources/images/workflow-img-2@2x.jpg" alt="flow-1" />
                  </Grid>

                </Grid>
                <Grid container spacing={this.state.isMobile ? 0 : 8}>
                  <ArrowLeftWhite />
                  <VisibilitySensor partialVisibility="top" offset={{ top: 300 }} partialVisibility={true}>
                    {({ isVisible }) =>
                      <div className={`disapear ${isVisible ? 'appear' : ''} dotline rotate-180`}>
                        <svg className='path-svg path-2' data-name="Layer 2" xmlns="http://www.w3.org/2000/svg">
                          <title>dash-path-1</title>
                          <path className="path" d="M381.6,127.8c-22.7,71,33.3,139.6,90.9,170c144,76,325.3,19.3,471,150c28.5,34.8,60.8,84.2,60.9,138" />
                          <path className="dashed" d="M381.6,127.8c-22.7,71,33.3,139.6,90.9,170c144,76,325.3,19.3,471,150c28.5,34.8,60.8,84.2,60.9,138" />
                        </svg>
                      </div>
                    }
                  </VisibilitySensor>
                  <Grid item xs={6} md={4} className='position-relative'>
                    <img className='w-100' src="/resources/images/workflow-img-3@2x.jpg" alt="flow-1" />
                  </Grid>
                  <Grid item xs={6} md={8} className="step-01">
                    <img src="/resources/images/num-03.svg" alt="03" />
                    <div className="content">
                      <h5 className="is-text-white">
                        個人與企業用戶前期研究
                    </h5>
                      <div className="body is-text-gray">
                        探索不同身份用戶在電信業數位服務上的痛點、需求、未來發展機會點。
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={this.state.isMobile ? 0 : 8}>
                  <ArrowRightWhite />
                  <VisibilitySensor partialVisibility="top" offset={{ top: 300 }} partialVisibility={true}>
                    {({ isVisible }) =>
                      <div className={`disapear ${isVisible ? 'appear' : ''} dotline`}>
                        <svg className='path-svg path-2' data-name="Layer 2" xmlns="http://www.w3.org/2000/svg">
                          <title>dash-path-1</title>
                          <path className="path" d="M381.6,127.8c-22.7,71,33.3,139.6,90.9,170c144,76,325.3,19.3,471,150c28.5,34.8,60.8,84.2,60.9,138" />
                          <path className="dashed" d="M381.6,127.8c-22.7,71,33.3,139.6,90.9,170c144,76,325.3,19.3,471,150c28.5,34.8,60.8,84.2,60.9,138" />
                        </svg>
                      </div>
                    }
                  </VisibilitySensor>
                  <Grid item xs={6} md={8} className='step-02'>
                    <img src="/resources/images/num-04.svg" alt="04" />
                    <h5 className="is-text-white">
                      易用性測試
                    </h5>
                    <div className="body is-text-gray mb-0">
                      接續探索性使用者研究發現，對尚未釋出的改版設計進行驗證性質的易用性測試，持續調整改版設計。
                  </div>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <img className='w-100' src="/resources/images/workflow-img-4@2x.jpg" alt="flow-1" />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="body is-text-coral70 is-text-bold align-center" style={{ marginTop: this.state.isMobile ? '50px' : '100px' }}>
                    官網優化改版設計專案中，我們執行了以下研究活動...
                  </div>
                </Grid>
                <Grid container spacing={this.state.isMobile ? 0 : 8} className="mt-0 mt-md-3 pb-0 pb-md-7 summary">
                  <Grid item xs={6} md={3} className="align-center">
                    <div className="d-block is-text-coral70 is-text-bold large-display">14</div>
                    <div className="body is-text-coral70 is-text-bold">
                      高階經營團隊訪談
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} className="align-center">
                    <div className="d-block is-text-coral70 is-text-bold large-display">99</div>
                    <div className="body is-text-coral70 is-text-bold">
                      全台遠傳門市顧客快訪
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} className="align-center">
                    <div className="d-block is-text-coral70 is-text-bold large-display">45</div>
                    <div className="body is-text-coral70 is-text-bold">
                      個人及企業用戶訪談
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} className="align-center">
                    <div className="d-block is-text-coral70 is-text-bold large-display">21</div>
                    <div className="body is-text-coral70 is-text-bold">
                      易用性測試
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="journey-map">
              <div className="fui-container">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <div className='is-text-darkgray30 is-text-bold journey-map-title'>顧客旅程地圖</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h5 className='is-text-darkgray50 mt-1 mb-4'>盤點消費者接觸電信服務的每一個細節，挖掘機會點</h5>
                    <h6 className='is-text-darkgray30 is-text-regular'>我們透過建構數位體驗藍圖描繪顧客與接觸點互動的樣貌，聚焦消費者和電信商接觸最頻繁的任務，以接觸頻率做細部區分，分析現況與痛點，找出設計轉換的機會點。</h6>
                  </Grid>
                  <div className="map-list">
                    {this.state.mapList.map((item, index) => {
                      return (
                        <button key={index + item} className={this.state.mapIndex === index ? 'active' : ''} onClick={() => this.mapIndex(index)}>{item}</button>
                      )
                    })}
                  </div>
                </Grid>
                <Grid item xs={12} md={12} className="mt-3">
                  {this.state.mapIndex === 0 ? <table className="journey-table">
                    <tbody className="">
                      <tr>
                        <th style={{ width: 130 }} className='is-text-bold'>週期</th>
                        <th colSpan="6">2 - 3 年</th>
                      </tr>
                      <tr>
                        <td className="is-text-bold">階段說明</td>
                        <td className="align-left vertical-top break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Aware</div>
                          <div className="d-inline">
                            三項要素形成日後的判斷準則：<br />
                        1. 到期日與對應違約金<br />
                        2. 歷史用量<br />
                        3. 目標手機
                        </div>
                        </td>
                        <td style={{ borderBottom: 0 }} className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Explore</div>
                          <div className="d-inline">
                            透過多元管道搜集資訊，除了電信業者經營的通路外，論壇心得亦是露出品牌印象的關鍵。
                          </div>
                        </td>
                        <td className="vertical-top" className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Decide</div>
                          <div className="d-inline">
                            攜碼成為常識，收訊品質和價格合理為最大考量。
                          </div>
                        </td>
                        <td style={{ borderBottom: 0 }} className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Explore</div>
                          <div className="d-inline">
                            透過多元管道搜集資訊，除了電信業者經營的通路外，論壇心得亦是露出品牌印象的關鍵。
                          </div>
                        </td>
                        <td className="vertical-top" className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Apply</div>
                          <div className="d-inline">
                            電信合約長達2-3年，比起流程冗長，更在乎明確的回饋。
                          </div>
                        </td>
                        <td className="vertical-top" className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Confirm</div>
                          <div className="d-inline">
                            確認合約明細與產品品質，以保障後續 2 - 3 年的生活必需品。
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">心情</td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">🤔</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">😑</h4>
                        </td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">🤔</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">😫</h4>
                        </td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">🙂</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">😲</h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">痛點</td>
                        <td className="break-spaces">
                          經歷了一個合約週期，顧客仍無法明確回答自己的需求，只有「找優惠」的原則。
                      </td>
                        <td className="break-spaces">
                          內容空泛、制式、依照 KPI 推薦，顧客無法找出理想目標。
                      </td>
                        <td className="break-spaces">
                          除了價格以外看不出各方案的優勢。
                      </td>
                        <td className="break-spaces">
                          想要隱藏版優惠一定要親自到線下問，沒有真人解答前都不敢輕易做決定。
                      </td>
                        <td className="break-spaces">
                          線上申辦存在許多不確定性，只能依靠猜測行動，因此傾向到線下完成。
                      </td>
                        <td className="break-spaces">
                          線上申辦的重點在超商或顧客家，沒有專業人士可供諮詢。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">數位接觸點</td>
                        <td colSpan="6" style={{ backgroundColor: '#f9f9f9' }}>
                          網站、APP、簡訊，自行查找資費、查看Q&A問題集、透過線上客服詢問...。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">線下接觸點</td>
                        <td colSpan="6" style={{ backgroundColor: '#f9f9f9' }}>
                          門市、線下客服、其他實體通路，自各家門市索取DM、接獲電話客服推銷、依門市人員解說合約、依照服務人員指示完成續約...。
                      </td>
                      </tr>
                    </tbody>
                  </table> : null}
                  {this.state.mapIndex === 1 ? <table className="journey-table">
                    <tbody className="">
                      <tr>
                        <th style={{ width: 130 }} className='is-text-bold'>週期</th>
                        <th colSpan="2">1- 2 個月</th>
                      </tr>
                      <tr>
                        <td className="is-text-bold">階段說明</td>
                        <td className="align-left vertical-top break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Aware</div>
                          <div className="align-center">
                            最熟悉的例行性業務，成為固定檢視使用狀況的契機。
                        </div>
                        </td>
                        <td style={{ borderBottom: 0 }} className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Action</div>
                          <div className="d-inline">
                            繳款週期拉長，線上繳款、自動扣款接受度高。
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">心情</td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">🤔</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">😐</h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">痛點</td>
                        <td className="break-spaces align-left">
                          透過多元方式獲取帳單，顧客最在乎明細，但數位工具未能完全解答疑惑。
                      </td>
                        <td className="align-left">
                          繳費是最頻繁發生的任務，但未能把握機會與客戶互動，對業者的印象停留在收款機構。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">數位接觸點</td>
                        <td colSpan="2" style={{ backgroundColor: '#f9f9f9' }}>
                          網站、APP、簡訊，查詢帳單與明細、線上刷卡或轉帳。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">線下接觸點</td>
                        <td colSpan="2" style={{ backgroundColor: '#f9f9f9' }}>
                          收到郵寄的紙本帳單、以現金或信用卡繳費、撥打客服電話刷卡繳費、至超商繳費。
                      </td>
                      </tr>
                    </tbody>
                  </table> : null}
                  {this.state.mapIndex === 2 ? <table className="journey-table">
                    <tbody className="">
                      <tr>
                        <th style={{ width: 130 }} className='is-text-bold'>週期</th>
                        <th colSpan="2">任何時後</th>
                      </tr>
                      <tr>
                        <td className="is-text-bold">階段說明</td>
                        <td className="align-left vertical-top break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Aware</div>
                          <div className="break-spaces" style={{ maxWidth: 224, margin: '0 auto' }}>
                            三個顧客最常詢問的問題類型：<br />
                            1.收訊問題<br />
                            2.方案問題<br />
                            3.使用狀況
                        </div>
                        </td>
                        <td style={{ borderBottom: 0 }} className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Action</div>
                          <div className="d-inline">
                            問題複雜，單靠一個管道無法完全解決。
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">心情</td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">🙁</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">😡</h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">痛點</td>
                        <td className="break-spaces align-left">
                          顧客對問題本身的容忍度是高的，容易累積不滿的是在後續的處理方式及回饋。
                      </td>
                        <td className="align-left">
                          各方說法不一或罐頭回覆，顧客沒有辦法採取正確的行動，態度不佳可能導致負面品牌形象。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">數位接觸點</td>
                        <td colSpan="2" style={{ backgroundColor: '#f9f9f9' }}>
                          以文字和線上客服對話。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">線下接觸點</td>
                        <td colSpan="2" style={{ backgroundColor: '#f9f9f9' }}>
                          收訊尋求客服人員協助、撥打專線問問題。
                      </td>
                      </tr>
                    </tbody>
                  </table> : null}
                  {this.state.mapIndex === 3 ? <table className="journey-table">
                    <tbody className="">
                      <tr>
                        <th style={{ width: 130 }} className='is-text-bold'>週期</th>
                        <th colSpan="5">-</th>
                      </tr>
                      <tr>
                        <td className="is-text-bold">階段說明</td>
                        <td className="align-left vertical-top break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Aware</div>
                          <div className="d-inline">
                            加值服務並非經常性需求，與個人生活型態有關。
                        </div>
                        </td>
                        <td style={{ borderBottom: 0 }} className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Explore</div>
                          <div className="d-inline">
                            與其他平台比較，競爭者不限於電信業者。
                          </div>
                        </td>
                        <td className="vertical-top" className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Join</div>
                          <div className="w-100 align-left">
                            加入動機包含以下三種：<br />
                            1. 價格<br />
                            2. 內容<br />
                            3. 生態系
                          </div>
                        </td>
                        <td style={{ borderBottom: 0 }} className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Use</div>
                          <div className="d-inline">
                            體驗付費會員權益以評估未來是否持續使用。
                          </div>
                        </td>
                        <td className="vertical-top" className="break-spaces">
                          <div className="subtitle is-text-bold align-center mb-1">Engage / Drop</div>
                          <div className="d-inline">
                            加值服務並非經常性需求，彈性的訂閱制度是基本功能。
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">心情</td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">😦</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">🤔</h4>
                        </td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">😁</h4>
                        </td>
                        <td className="">
                          <h4 className="m-0">😵</h4>
                        </td>
                        <td style={{ borderTop: 0 }}>
                          <h4 className="m-0">😡</h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">痛點</td>
                        <td className="break-spaces">
                          數位生活品牌認知度低，多需要仰賴門市人員推薦。
                      </td>
                        <td className="break-spaces">
                          顧客對加值服務有需求，但對電信商沒有要求。
                      </td>
                        <td className="break-spaces">
                          如果沒有穩定的品質，難以留住顧客。
                      </td>
                        <td className="break-spaces">
                          使用狀態不明確，無從查詢，須主動設定提醒，否則等到帳單來才知道冤大頭。
                      </td>
                        <td className="break-spaces">
                          理所當然認為可以透過線上完成相關設定，但必須仰賴線下，異動困難，因此產生被電信商綁架的負面感覺。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">數位接觸點</td>
                        <td colSpan="5" style={{ backgroundColor: '#f9f9f9' }}>
                          網站、APP、簡訊，自行查找資費、查看Q&A問題集、透過線上客服詢問...。
                      </td>
                      </tr>
                      <tr>
                        <td className="is-text-bold">線下接觸點</td>
                        <td colSpan="5" style={{ backgroundColor: '#f9f9f9' }}>
                          門市、線下客服、其他實體通路，自各家門市索取DM、接獲電話客服推銷、依門市人員解說合約、依照服務人員指示完成續約...。
                      </td>
                      </tr>
                    </tbody>
                  </table> : null}
                </Grid>
                <Grid item xs={12} md={12} className="mt-3">
                  <img className='d-flex w-100' style={{ maxHeight: this.state.isMobile ? 150 : 296 }} src="/resources/images/mr-question.svg" alt="question" />
                </Grid>
              </div>
            </div>
            <div className="communication">
              <div className="fui-container">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <div className='communication-title is-text-bold'>設計溝通的關鍵時刻</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h5 className='mt-1 mb-4'>揮別突兀場景，創造精心設計的偶遇</h5>
                    <h6 className='is-text-gray is-text-regular mb-0 mt-3 mt-md-5'>前期使用者研究發現，電信數位服務旅程中，在合適的時刻提供給顧客訊息，是溝通成敗的關鍵。<br />每個人會接觸到電信業的事情不同，有具備強烈目的、非常聚焦的如危機處理、申辦門號；也有心態輕鬆、蒐集資訊的如決定要用哪一種數位服務的時刻。選擇在合適的時刻提供對應的資訊，會是溝通訊息能否進到消費者腦海的重要因素。</h6>
                  </Grid>
                  <Grid item xs={12} className="design-change justify-center d-flex">
                    <img className='d-none d-md-block' src="/resources/images/design-change.svg" alt="change" />
                    <img className='d-block d-md-none' src="/resources/images/design-change-mb.svg" alt="change" />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <div className='communication-title is-text-bold'>策略溝通，收斂設計概念</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h6 className='is-text-gray is-text-regular mt-0'>遠傳企業內部在數位能力與資源整合的經驗豐富，與AJA設計團隊持續共創、傳遞服務價值，這一年中，我們建立一套高效率的溝通流程，透過用戶歷程地圖與分析用戶輪廓的工具，幫助不同部門快速地了解市場現況，並拋出目前部門面臨的議題，從中挖掘可以解決的設計方案。</h6>
                  </Grid>
                  <VisibilitySensor offset={{ top: 300 }} partialVisibility="top" partialVisibility={true}>
                    {({ isVisible }) =>
                      <div className={`disapear ${isVisible ? 'appear' : ''} circle-image`}>
                        <img className='circle-text' src="/resources/images/circle-text.svg" alt="circle" />
                        <img className='circle-item circle-6' src="/resources/images/circle-6.svg" alt="circle" />
                        <img className='circle-item circle-5' src="/resources/images/circle-5.svg" alt="circle" />
                        <img className='circle-item circle-4' src="/resources/images/circle-4.svg" alt="circle" />
                        <img className='circle-item circle-3' src="/resources/images/circle-3.svg" alt="circle" />
                        <img className='circle-item circle-2' src="/resources/images/circle-2.svg" alt="circle" />
                        <img className='circle-item circle-1' src="/resources/images/circle-1.svg" alt="circle" />
                        <img className='circle-dot' src="/resources/images/circle-dot.svg" alt="circle" />
                        <img className='circle-bg' src="/resources/images/circle.svg" alt="circle" />
                      </div>
                    }
                  </VisibilitySensor>
                </Grid>
              </div>
            </div>
            <div className="evolution">
              <div className="fui-container">
                <Grid container spacing={this.state.isMobile ? 3 : 6}>
                  <Grid item xs={12} md={4} className="mb-0 mb-md-3">
                    <div className='evolution-title is-text-bold is-text-darkgray30'>驗證設計，持續迭代優化</div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <h6 className='is-text-darkgray30 is-text-regular mt-1'>參考探索性研究發現發展設計的同時，我們也邀請用戶來評價，給予真實建議。</h6>
                  </Grid>
                  <Grid item xs={12} md={12} className="align-center" style={{ marginTop: -50, zIndex: 0, marginBottom: -30 }}>
                    <img className='how' style={{ width: 240 }} src="/resources/images/how@2x.png" alt="HOW" />
                  </Grid>
                  <ArrowLeftWhite color="gray" />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <VisibilitySensor offset={{ top: 300 }} partialVisibility="top" partialVisibility={true}>
                        {({ isVisible }) =>
                          <div className={`disapear ${isVisible ? 'appear' : ''}`}>
                            <Grid item xs={12} md={12} className="brand-slider">
                              <div className="d-flex d-md-none flex-column">
                                <p className='d-flex is-text-darkgray50 mb-2 align-center flex-align-center'>
                                  <img style={{ width: 14, height: 20 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                                  如何邀請繁忙的微型企業老闆參與易用性測試？
                                </p>
                                <h4 className="is-font-bold">
                                  半遠距測試
                              </h4>
                              </div>
                              <Grid item xs={12} md={12}>
                                <img className='evo-01 w-100 d-flex' src="/resources/images/usability-test-img-1.jpg" alt="revolution-1" />
                              </Grid>
                              <p className='m-md-0 mt-4 d-block d-md-none pr-md-0 mr-10 mb-0'>
                                離開實驗室場域，直接到微型企業老闆最熟悉的場域（店內）體驗最新的設計原型，提供第一手看法。
                              </p>
                            </Grid>
                          </div>
                        }
                      </VisibilitySensor>
                      <Grid item xs={12} md={12} className="title d-md-flex d-none flex-column">
                        <p className='d-flex is-text-darkgray50 mb-1 mt-2'>
                          <img className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                          如何邀請繁忙的微型企業老闆參與易用性測試？
                        </p>
                        <h4 className="is-font-bold mt-0 mb-6">
                          半遠距測試
                        </h4>
                        <p className='m-0 body'>
                          離開實驗室場域，直接到微型企業老闆最熟悉的場域（店內）體驗最新的設計原型，提供第一手看法。
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} className="evo-right">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} className="brand-slider">
                        <div className="d-flex d-md-none flex-column">
                          <p className='d-flex is-text-darkgray50 mb-2 align-center body flex-align-center'>
                            <img style={{ width: 14, height: 20 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                            如何對個人用戶驗證？
                          </p>
                          <h4 className="is-font-bold">
                            易用性測試
                          </h4>
                        </div>
                        <VisibilitySensor offset={{ top: 500 }} partialVisibility="top" partialVisibility={true}>
                          {({ isVisible }) =>
                            <div className={`disapear ${isVisible ? 'appear' : ''}`}>
                              <Grid item xs={12} md={12}>
                                <img className='how' src="/resources/images/workflow-img-4@2x.jpg" alt="revolution-1" />
                              </Grid>
                            </div>
                          }
                        </VisibilitySensor>
                        <p className='m-md-0 mt-4 d-block d-md-none pr-md-0 mr-10 mb-0'>
                          邀請用戶操作網頁上線的版本執行任務，再從用戶反饋中修正潛在的設計問題。
                        </p>
                      </Grid>

                      <Grid item xs={12} md={12} className="title d-md-flex d-none flex-column">
                        <p className='d-flex is-text-darkgray50 mb-1 mt-2 body'>
                          <img className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                                如何對個人用戶驗證？
                              </p>
                        <h4 className="is-font-bold mt-0 mb-6">
                          易用性測試
                              </h4>
                        <p className='m-0 body'>
                          邀請用戶操作網頁上線的版本執行任務，再從用戶反饋中修正潛在的設計問題。
                              </p>
                      </Grid>

                    </Grid>
                    <ArrowRightWhite color="gray" top={-400} />
                  </Grid>
                </Grid>
              </div>
            </div>
            <PlatformCard />
          </div>
        </div>

      </main >
    )
  }
}
export default ResearchMethod