import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import ArrowLeftWhite from '../components/animateArrow/ArrowLeftWhite';
import RedArrow from '../components/animateArrow/RedArrow';
import VisibilitySensor from 'react-visibility-sensor';
import path1 from '../components/path/dash-path-1.svg'
import * as Mock from '../mock/CorpIndex';
import zIndex from '@material-ui/core/styles/zIndex';
import PlatformCard from '../components/PlatformCard'
class ResearchMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: window.innerWidth,
      mapIndex: 0,
      mapList: ['首頁分類快捷', '主選單架構設計', '企業規模分流', '搜尋系統', '頁面層級定義'],
      isMobile: window.innerWidth < 960,
      sliderSetting: {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        draggable: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              variableWidth: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              variableWidth: true
            }
          }
        ],
      },
      sliderOne: [
        {
          image: '/resources/images/arch-information-slide-1@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch-information-slide-1@2x.jpg',
          alt: '01'
        },
        {
          image: '/resources/images/arch-information-slide-1@2x.jpg',
          alt: '01'
        },
      ],
    }
  }
  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        width: window.innerWidth,
        isMobile: window.innerWidth < 960
      })
    })
  }
  mapIndex(val) {

    this.setState({
      mapIndex: val
    })

  }
  render() {
    return (
      <main className='research-method promotion'>
        <div>
          <div className='animation-wrapper' style={{ position: 'absolute', height: 557 }}>
            <div className="top-animation" style={{ zoom: this.state.width < 960 ? this.state.width / 1000 : 1 }}>
              <div className="triangle-bg"></div>
              <img className='bg-1' src="/resources/images/index-animation/banner-chapter-1-triangle-1.svg" alt="bg" />
              {/* <img className='bg-2' src="/resources/images/index-animation/banner-chapter-1-visual-2@2x.png" alt="bg-1" /> */}
              <img className='bg-3' src="/resources/images/index-animation/banner-chapter-1-visual-3.svg" alt="bg-2" />
              <img className='bg-4' src="/resources/images/index-animation/banner_chapter1_visual-3@2x.png" alt="bg-3" />
              <img className='bg-5' src="/resources/images/index-animation/banner-chapter-1-triangle-group.svg" alt="bg-4" />
            </div>
          </div>
          <div className='stickyroll-content'>
            <div className="top" >
              <div className="left" />
              <div className="right" />
            </div>
            <div className="research-method-page-1">
              <div className="align-center wrapper">
                <div className="body is-text-bold mb-md-3 mb-0">
                  情境式行銷
                </div>
                <h2 className='mt-0'>活絡集團資源與跨售，數位服務客製化讓廣告更有感</h2>
              </div>
            </div>
            <div className="research-method-page-2">
              <div className="fui-container">
                <VisibilitySensor partialVisibility={true}>
                  {({ isVisible }) =>
                    <Grid container spacing={this.state.isMobile ? 2 : 6} className={`disapear content-block ${isVisible ? 'appear' : ''}`}>
                      <Grid item xs={12} md={4}>
                        <div className='is-text-white content-title is-text-bold'>縮短願景與需求的距離</div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <h6 className='is-text-gray is-text-regular mb-md-0 mb-5 mt-1'>遠傳的優勢在於跨集團的資源，若在網站展現此一特色，有機會吸引重視價值的客戶，因豐富性而聚焦於遠傳。像是 Happy GO 點數、亞東醫院健檢，對用戶來說都是有吸引力的服務加值，完整且豐富的生態系帶給他們「一次到位」的快感。</h6>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <img className='w-100 d-none d-md-flex' src="/resources/images/marketing-lifecircle.png" alt="marketing-lifecircle" />
                        <img className='w-100 d-flex d-md-none' src="/resources/images/marketing-lifecircle-mb.png" alt="marketing-lifecircle" />
                      </Grid>
                    </Grid>
                  }
                </VisibilitySensor>
              </div>
            </div>
            <div className="proposal">
              <div className="fui-container">
                <VisibilitySensor partialVisibility={true}>
                  {({ isVisible }) =>
                    <Grid container spacing={this.state.isMobile ? 0 : 6} className={`disapear content-block ${isVisible ? 'appear' : ''}`}>
                      <Grid item xs={12} md={4}>
                        <div className='is-text-darkgray50 content-title is-text-bold mb-3 mb-md-0'>生活型態提案，發揮產品的多元綜效</div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <h6 className='is-text-darkgray50 is-text-regular mb-md-0 mb-3 mt-1'>我們從現代生活型態發想，分析每一個讓用戶產生對服務興趣的契機，發現用戶的生活需求非單一面向，而是跨越場景和時間等多元需求。用戶對產品推銷無感，卻對內容有興趣。生活提案 Content Driven，取代傳統的販售商品，用主題企劃和吸引人的內容，讓客戶自己找上門。</h6>
                      </Grid>
                      <Grid item xs={12} md={12} className=''>
                        <img className='w-100 d-flex d-md-none' src="/resources/images/binge_watch_m@2x.png" alt="binge-watch" />
                        <img style={{ marginBottom: -68 }} className='binge-watch w-100 d-none d-md-flex' src="/resources/images/binge-watch@2x.png" alt="binge-watch" />
                        <img className='w-100 d-none d-md-flex' src="/resources/images/living-circle-mockup@2x.png" alt="living-circle-mockup" />
                        <img className='w-100 d-flex d-md-none' src="/resources/images/living_circle_mockup_m@2x.png" alt="living-circle-mockup" />
                      </Grid>
                    </Grid>
                  }
                </VisibilitySensor>
              </div>
            </div>
            <div className="ebu">
              <div className="fui-container">
                <VisibilitySensor partialVisibility={true}>
                  {({ isVisible }) =>
                    <Grid container spacing={this.state.isMobile ? 2 : 6} className={`disapear content-block ${isVisible ? 'appear' : ''}`}>
                      <Grid item xs={12} md={4}>
                        <div className='content-title is-text-bold'>場域共感探觸難言痛處，規劃事業藍圖</div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <h6 className='is-text-regular is-text-gray mb-0 mt-1'>台灣頭家多仰賴少數人力薄利經營，僅靠單臂之力搜尋資訊和前輩經驗交流。由於必須在短時間內成為管理通才，數位化需求相對高。我們透過場域共感的設計，輔助頭家探索事業需求，連結經營痛點。無論在哪個經營階段、哪種店面空間，都能先在網站上演習一番，模擬事業計畫書，再將需求順利地嫁接專人，達到線上溝通的實質效益。</h6>
                      </Grid>
                      <div className="ebu-images">
                        <img src="/resources/images/startup-plan-1@2x.png" alt="startup-plan-1" />
                        <img src="/resources/images/startup-plan-2@2x.png" alt="startup-plan-2" />
                        <img src="/resources/images/startup-plan-3@2x.png" alt="startup-plan-3" />
                      </div>
                      <VisibilitySensor partialVisibility={true}>
                        {({ isVisible }) =>
                          <h4 className={`disapear quote ${isVisible ? 'appear' : ''}`}>
                            開店選址不再靠感覺，智能開店選址服務利用即時、真實的電信大數據
                  </h4>
                        }
                      </VisibilitySensor>
                      <Grid item xs={12} md={12} className='pb-0'>
                        <img className='w-100 d-none d-md-flex' src="/resources/images/startup-plan-mockup@2x.png" alt="desktop-mobile-demo" />
                        <img className='desktop-mobile-demo w-100 d-flex d-md-none' src="/resources/images/startup_plan_mockup_m@2x.png" alt="desktop-mobile-demo" />
                      </Grid>
                    </Grid>
                  }
                </VisibilitySensor>
              </div>
            </div>
            <PlatformCard />
          </div>
        </div>

      </main >
    )
  }
}
export default ResearchMethod