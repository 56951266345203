import React from 'react';
import {Parallax} from 'react-scroll-parallax';

const ArrowRightWhite = (props) => {
    return (
        <Parallax className="animate-arrow-right" x={['50%', '-150%']}>
            <div style={{marginTop: props.top}}>
                {props.color!=='gray'?
                <img src={process.env.PUBLIC_URL + '/resources/images/triangle-style-2.svg'} alt="triangle"/>
                :<img src={process.env.PUBLIC_URL + '/resources/images/triangle-style-2-gray.svg'} alt="triangle"/>
                }
            </div>
        </Parallax>
    );
}

export default ArrowRightWhite;