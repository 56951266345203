import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../components/loading/Loading.js';
import store from '../stores';
import { trackPageViewStart, trackPageViewEnd, trackActionTrigger } from '../stores/action';

import '../sass/loader.sass';

window.hash = '';
window.isScroll = false;
window.prevHistory = {
  pathname: '',
  hash: '',
  key: '',
};

const Loader = (props) => {
  const history = useHistory();

  const [timer, setTimer] = React.useState(null);

  React.useLayoutEffect(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    if (typeof window !== 'undefined') {
      //console.info('Loader useLayoutEffect');

      if (window.prevHistory.pathname !== window.location.pathname) {
        //start
        // document.body.className = "";
        // detectPath();
        // $html.className = "is-loading is-loading-block";
        // $html.classList.remove("fade-in");
        // $body.classList.add("is-loading");
        let elem = window || document.documentElement;

        window.prevHistory = {
          origin: window.location.origin,
          ...window.location,
        };

        // elem.scroll(0, 0);
        setTimer(
          setTimeout(() => {
            setTimer(clearTimeout(timer));
            loadingAnimate();
            if (window.location.hash) {
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  console.info('history.listen1 gotoHash');
                  gotoHash(window.location.hash);
                }, 300)
              );
            }
            //end
          }, 50)
        );
      }

      if (
        window.prevHistory.pathname === window.location.pathname &&
        window.location.hash !== window.prevHistory.hash
      ) {
        window.isScroll = false;
        window.prevHistory = window.location;
        //console.info('history.listen2 gotoHash');
        gotoHash(window.location.hash);
      }
    }
  });

  React.useEffect(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    if (typeof window !== 'undefined') {
      //console.info('Loader useEffect');

      window.onload = (e) => {
        e.preventDefault();
        //console.info('[Loader] useEffect onLoad', window.location.hash);

        document.body.className = '';
        detectPath();
        loadingAnimate();
        if (window.location.hash !== '') {
          //console.info('[Loader] has Hash');
          setTimer(
            setTimeout(() => {
              setTimer(clearTimeout(timer));
              window.isScroll = false;
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  //console.info('useEffect gotoHash');
                  gotoHash(window.location.hash);
                }, 300)
              );
            }, 1500)
          );
        }

        window.prevHistory = {
          pathname: window.location.pathname,
          hash: window.location.hash,
          origin: window.location.origin,
          search: window.location.search,
        };
      };
    }

    return function cleanup() {
      unlisten();
    };
  }, [loadingAnimate, timer, gotoHash, history, unlisten]);

  const unlisten = history.listen((listener, action) => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    if (typeof window !== 'undefined') {
      if (window.prevHistory.pathname === '') {
        window.prevHistory = listener;
      }
      //console.info('history.listen');

      if (window.prevHistory.pathname !== listener.pathname) {
        //start
        document.body.className = '';
        detectPath();
        $html.className = 'is-loading is-loading-block';
        $html.classList.remove('fade-in');
        $body.classList.add('is-loading');
        let elem = window || document.documentElement;

        window.prevHistory = {
          origin: window.location.origin,
          ...listener,
        };

        // elem.scroll(0, 0);
        setTimer(
          setTimeout(() => {
            setTimer(clearTimeout(timer));
            loadingAnimate();
            if (listener.hash) {
              setTimer(
                setTimeout(() => {
                  setTimer(clearTimeout(timer));
                  //console.info('history.listen1 gotoHash');
                  gotoHash(listener.hash);
                }, 300)
              );
            }
            //end
          }, 50)
        );
      }

      if (window.prevHistory.pathname === listener.pathname && listener.hash !== window.prevHistory.hash) {
        window.isScroll = false;
        window.prevHistory = listener;
        //console.info('history.listen2 gotoHash');
        gotoHash(listener.hash);
      }
    }
  });

  const detectPath = () => {
    const query = new URLSearchParams(window.location.search);

    if (window.channelId === 'SUPPERAPP' || (query.get('channelId') && query.get('channelId') === 'SUPERAPP')) {
      document.body.classList.add('is-webview');
    }
    document.body.classList.add('is-corp');

    if (window.location.pathname.indexOf('help-center') > -1) {
      document.body.classList.add('is-help-center');
    }
    // else {
    //   if (window.location.pathname.indexOf('/ebu/') > -1) {
    //     document.body.classList.add('is-ebu');
    //   }
    // }
  };

  const gotoHash = useCallback((hash) => {
    if (hash === '#' || hash === '' || !document.getElementById(hash.slice(1))) return;
    let target = document.getElementById(hash.slice(1));
    let rect = target.getBoundingClientRect();
    let elem = window || document.documentElement;
    let scrollY = window.scrollY || document.documentElement.scrollTop;

    //console.info(`gotoHash`, rect.top, scrollY);
    // if (window.isIE) {
    //   //console.info(`isIE`);
    //   elem.scroll(0, rect.top + scrollY - 100);
    // } else {
    //   //console.info(`notIE`);
    //   elem.scrollTo({
    //     left: 0,
    //     top: scrollY + rect.top - 100,
    //     behavior: 'smooth',
    //   });
    // }

    setTimer(
      setTimeout(() => {
        window.isScroll = true;
        setTimer(clearTimeout(timer));
      }, 200)
    );
  });

  const loadingAnimate = useCallback(() => {
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    window.loading = true;
    setTimer(
      setTimeout(() => {
        if (window.prevHistory.pathname !== '') {
          if (window.isIE) {
            let elem = window || document.documentElement;
            // elem.scroll(0, 0);
          } else {
            // window.scrollTo(0, 0);
          }
          if ($body.getElementsByClassName('fui-header').length)
            $body.getElementsByClassName('fui-header')[0].setAttribute('style', '');
        }

        $html.classList.add('fade-in');
        $html.classList.remove('is-loading-block');
        $html.classList.remove('is-loading');
        $body.classList.remove('is-loading');
        setTimeout(() => {
          $html.classList.remove('fade-in');
        }, 1500);

        window.loading = false;
        setTimer(clearTimeout(timer));
      }, 200)
    );
  });

  return (
    <section className='loading'>
      <Loading />
    </section>
  );
};

export default Loader;
