import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Grid } from '@material-ui/core';
import Link from '../components/Link';
import Slider from 'react-slick';
import ArrowLeftWhite from '../components/animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../components/animateArrow/ArrowRightWhite';
import VisibilitySensor from 'react-visibility-sensor';
import * as Mock from '../mock/CorpIndex';
import zIndex from '@material-ui/core/styles/zIndex';
import PlatformCard from '../components/PlatformCard'
const headlines = [
  "Hello World!",
  "Hello React!",
  "Hello Stickyroll!",
  "Let's continue with the next lesson!"
]
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: window.innerWidth,
      sliderSetting: {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        draggable: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              variableWidth: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              variableWidth: true
            }
          }
        ],
      },
      sliderOne: [
        {
          image: '/resources/images/workshop-img-1@2x.jpg',
          alt: '01'
        }
      ],
      sliderTwo: [
        {
          image: '/resources/images/research-interview-img-1@2x.jpg',
          alt: '01'
        }
      ],
      sliderThree: [
        {
          image: '/resources/images/life-circle-img-1@2x.jpg',
          alt: '01'
        }
      ],
    }
  }
  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        width: window.innerWidth
      })
    })
  }
  getZoom() {
    let width = this.state.width;
    switch (true) {
      case (width > 1600):
        return 1
      case (width > 960):
        return this.state.width / 1600
      case (width > 640):
        return this.state.width / 1400
      default:
        return this.state.width / 1000

    }
  }
  render() {
    return (
      <main className='index'>
        <div>
          <div className='stickyroll-content'>
            <div className="page-1">
              <div className="triangle-bg"></div>
              <div className="index-animation" style={{ zoom: this.getZoom() }}>
                <img className='bg-1' src="/resources/images/index-animation/triangle-group@2x.png" alt="bg" />
                <img className='bg-2' src="/resources/images/index-animation/banner-main-visual-1@2x.png" alt="bg-1" />
                <img className='bg-3' src="/resources/images/index-animation/banner-main-visual-2@2x.png" alt="bg-2" />
                <img className='bg-4' src="/resources/images/index-animation/banner-main-visual-3@2x.png" alt="bg-3" />
                <img className='bg-5' src="/resources/images/index-animation/banner-main-visual-4@2x.png" alt="bg-4" />
              </div>
              <div className="align-center wrapper">
                <p className='is-text-light'>PROJECT</p>
                <div className="body is-text-bold mb-md-3 mb-0">
                  遠傳電信官網改版
                    </div>
                <h1 className='mt-0'>不只是電信，替未來生活提案</h1>
                <AnchorLink href='#head-content'>
                  <div className="go-down">
                    <img src="/resources/images/vector-135.svg" alt="down" />
                    <img src="/resources/images/vector-135.svg" alt="down" />
                  </div>
                </AnchorLink>
              </div>
            </div>
            <div className="page-2" id="head-content">
              <div className="top-bg" />
              <VisibilitySensor partialVisibility={true}>
                {({ isVisible }) =>
                  <div className={`body is-text-bold content disapear ${isVisible ? 'appear' : ''}`}>科技、通訊、網路已是現代人們生活中無法或缺的需求，但經過 499 之亂後(2018)，吃到飽資費被定錨在 499 上下，重組電信商在用戶心中的定位。當用戶選擇電信商時只剩下價格區別，也間接限制電信可創造的價值。因應 5G 時代的需求變革，遠傳電信與 AJA 的設計團隊合作突破現況，提煉出服務接觸點的價值，整合集團的多元服務，重塑商用與個人網站。</div>
                }
              </VisibilitySensor>
              <div className="top" />
              <div className="fui-container" style={{ zIndex: 1 }}>
                <VisibilitySensor partialVisibility={true}>
                  {({ isVisible }) =>
                    <Grid container className={`disapear ${isVisible ? 'appear' : ''}`} spacing={8}>
                      <Grid item xs={12} md={4}>
                        <h3 className="is-text-darkgray50 pl-md-10">
                          專案目標
                      </h3>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <h5 className="is-text-darkgray50 pt-1">
                          傳統電信如何因新科技而改變，融入消費者生活，為用戶靠得更近，想得更遠！
                        </h5>
                        <h6 className="is-text-darkgray50 is-text-regular mt-4">
                          我們與遠傳團隊歷經一年，梳理商用與個人官網的服務功能、資訊架構，透過用戶研究釐清現況議題。從業務邏輯和用戶需求之中取得最大值，與客戶團隊共同提出新網站的價值。同時，我們也考量到不同使用者年齡、數位程度、小網體驗優先，建構一套易於理解的資訊架構和介紹產品優勢的流程，讓消費者在網站上自由地探索需要的資訊，也順暢地完成電信任務。
                        </h6>
                      </Grid>
                    </Grid>
                  }
                </VisibilitySensor>
                <Grid container spacing={6}>
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid item xs={12} className={`pb-md-4 pb-0 disapear ${isVisible ? 'appear' : ''}`}>
                        <h5 className="is-text-darkgray50 align-center mb-0">
                          為此專案目標，我們共執行了
                    </h5>
                      </Grid>
                    }
                  </VisibilitySensor>
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid item xs={6} md={3} className={`disapear ${isVisible ? 'appear' : ''}`}>
                        <div className="reveal-card">
                          <img src="resources/images/goal-result-img-1@2x.jpg" alt="meeting" />
                          <div className="sum">
                            <h1 className="is-text-white">14</h1>
                            <h6 className="is-text-white">場</h6>
                            <div className="align-center">
                              <div className="triangle" />
                            </div>
                          </div>
                        </div>
                        <div className="reveal-card-body body is-text-darkgray50 align-center is-text-bold">高階經營團隊訪談</div>
                      </Grid>
                    }
                  </VisibilitySensor>
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid item xs={6} md={3} className={`disapear ${isVisible ? 'appear' : ''}`}>
                        <div className="reveal-card">
                          <img src="resources/images/goal-result-img-2@2x.jpg" alt="meeting" />
                          <div className="sum">
                            <h1 className="is-text-white">39</h1>
                            <h6 className="is-text-white">位</h6>
                            <div className="align-center">
                              <div className="triangle" />
                            </div>
                          </div>
                        </div>
                        <div className="reveal-card-body body is-text-darkgray50 align-center is-text-bold">消費者焦點團體訪談</div>
                      </Grid>
                    }
                  </VisibilitySensor>
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid item xs={6} md={3} className={`disapear ${isVisible ? 'appear' : ''}`}>
                        <div className="reveal-card">
                          <img src="resources/images/goal-result-img-3@2x.jpg" alt="meeting" />
                          <div className="sum">
                            <h1 className="is-text-white">450</h1>
                            <h6 className="is-text-white">個</h6>
                            <div className="align-center">
                              <div className="triangle" />
                            </div>
                          </div>
                        </div>
                        <div className="reveal-card-body body is-text-darkgray50 align-center is-text-bold">頁面流程優化</div>
                      </Grid>
                    }
                  </VisibilitySensor>
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid item xs={6} md={3} className={`disapear ${isVisible ? 'appear' : ''}`}>
                        <div className="reveal-card">
                          <img src="resources/images/goal-result-img-4@2x.jpg" alt="meeting" />
                          <div className="sum">
                            <h1 className="is-text-white">798</h1>
                            <h6 className="is-text-white">個</h6>
                            <div className="align-center">
                              <div className="triangle" />
                            </div>
                          </div>
                        </div>
                        <div className="reveal-card-body body is-text-darkgray50 align-center is-text-bold">頁面產出</div>
                      </Grid>
                    }
                  </VisibilitySensor>
                </Grid>
              </div>
            </div>
            <div className="page-3">
              <div className="fui-container">
                <div className="brand-story left">
                  <img className='how' src="/resources/images/how@2x.png" alt="HOW" />
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid container className={`disapear ${isVisible ? 'appear' : ''}`} spacing={4}>
                        <Grid item xs={12} md={5} className="brand-slider">
                          <div className="d-flex d-md-none flex-column">
                            <p className='d-flex is-text-darkgray50 mb-2 align-center'>
                              <img style={{ width: 14, height: 20 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                              如何傳遞品牌價值？
                            </p>
                            <h4 className="is-text-accent is-font-bold">
                              從感性的品牌精神，到理性的設計原則
                            </h4>
                          </div>
                          <Slider className='branding' {...this.state.sliderSetting}>
                            {this.state.sliderOne.map((card, idx) => (
                              <div key="idx" className='img'>
                                <img src={card.image} alt={card.alt} />
                              </div>
                            ))}
                          </Slider>
                        </Grid>
                        <Grid item xs={12} md={7} className="title d-flex flex-column justify-center">
                          <p className='d-md-flex is-text-darkgray50 mb-2 d-none'>
                            <img className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                            如何傳遞品牌價值？
                          </p>
                          <h4 className="d-none d-md-block is-text-accent is-font-bold mt-0 mb-6">
                            從感性的品牌精神，到理性的設計原則
                          </h4>
                          <p className='m-0'>
                            AJA 設計團隊舉辦遠傳品牌工作坊，邀請高階主管參與。我們用橋樑描繪出遠傳與用戶的溝通途徑，透過豐富圖卡拼出有形與無形的品牌價值，經揉合不同聲音後收斂，作為新官網視覺設計的發展依據。
                          </p>
                        </Grid>
                        <ArrowRightWhite color="gray" />
                      </Grid>
                    }
                  </VisibilitySensor>
                </div>
                <div className="brand-story right mt-2">
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid container className={`disapear ${isVisible ? 'appear' : ''}`} spacing={4}>
                        <Grid item xs={12} md={7} className="title d-md-flex d-none flex-column justify-center">
                          <p className='d-flex is-text-darkgray50 mb-2'>
                            <img className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                            如何透過消費者洞察發現機會點？
                          </p>
                          <h4 className="is-text-accent is-font-bold mt-0 mb-6">
                            探索消費者體驗的接觸點，建構與顧客互動的各種樣貌，提煉洞察觀點
                          </h4>
                          <p className='m-0'>
                            為了傾聽不同用戶聲音，AJA 研究員造訪 6 間位於北、中、南的遠傳直營與加盟門市，街訪近百位的用戶，執行 4 場焦點訪談。我們發現數位能力不是阻礙用戶使用電信線上服務的根本原因，而是市場上的電信商在銷售時都使用專業術語溝通，用戶因不熟悉申辦流程和產品功能，導致大眾的認知落差而產生負面印象。
                          </p>
                        </Grid>
                        <Grid item xs={12} md={5} className="brand-slider">
                          <div className="d-flex d-md-none flex-column">
                            <p className='d-flex is-text-darkgray50 mb-2 align-center'>
                              <img style={{ width: 14, height: 20 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                              如何透過消費者洞察發現機會點？
                            </p>
                            <h4 className="is-text-accent is-font-bold">
                              探索消費者體驗的接觸點，建構與顧客互動的各種樣貌，提煉洞察觀點
                            </h4>
                          </div>
                          <Slider className='branding' {...this.state.sliderSetting}>
                            {this.state.sliderTwo.map((card, idx) => (
                              <div key="idx" className='img'>
                                <img src={card.image} alt={card.alt} />
                              </div>
                            ))}
                          </Slider>
                          <p className='m-md-0 mt-4 d-block d-md-none pr-md-0 mr-10'>
                            為了傾聽不同用戶聲音，AJA 研究員造訪 6 間位於北、中、南的遠傳直營與加盟門市，街訪近百位的用戶，執行 4 場焦點訪談。我們發現數位能力不是阻礙用戶使用電信線上服務的根本原因，而是市場上的電信商在銷售時都使用專業術語溝通，用戶因不熟悉申辦流程和產品功能，導致大眾的認知落差而產生負面印象。
                          </p>
                        </Grid>
                        <ArrowLeftWhite color="gray" />
                      </Grid>
                    }
                  </VisibilitySensor>
                </div>
                <div className="brand-story left">
                  <VisibilitySensor partialVisibility={true}>
                    {({ isVisible }) =>
                      <Grid container className={`disapear ${isVisible ? 'appear' : ''}`} spacing={4}>
                        <Grid item xs={12} md={5} className="brand-slider">
                          <div className="d-flex d-md-none flex-column">
                            <p className='d-flex is-text-darkgray50 mb-2 align-center'>
                              <img style={{ width: 14, height: 20 }} className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                              如何透過電信服務創造生活圈？
                            </p>
                            <h4 className="is-text-accent is-font-bold">
                              在消費者接觸數位服務的機會中，創造偶遇的時刻，提供更有感的產品建議
                            </h4>
                          </div>
                          <Slider className='branding' {...this.state.sliderSetting}>
                            {this.state.sliderThree.map((card, idx) => (
                              <div key="idx" className='img'>
                                <img src={card.image} alt={card.alt} />
                              </div>
                            ))}
                          </Slider>
                        </Grid>
                        <Grid item xs={12} md={7} className="title d-flex flex-column justify-center">
                          <p className='d-md-flex is-text-darkgray50 mb-2 d-none'>
                            <img className='mr-1' src="/resources/images/triangle-red.svg" alt="triangle" />
                            如何透過電信服務創造生活圈？
                          </p>
                          <h4 className="d-none d-md-block is-text-accent is-font-bold mt-0 mb-6">
                            在消費者接觸數位服務的機會中，創造偶遇的時刻，提供更有感的產品建議
                          </h4>
                          <p className='m-0'>
                            隨著科技發展迅速，我們生活中已經有太多的數位產品服務可以選擇，尚未有一間電信商能清楚的告訴用戶，為什麼需要有數位產品服務？又該如何透過數位工具讓生活變得更好、更便利？用戶不需要更多的產品推銷，而是替忙碌的自己多想一步，提供有趣又多元的生活提案， 解決日常的繁瑣事務，有了更多餘裕享受當下。
                          </p>

                        </Grid>
                        <ArrowRightWhite color="gray" />
                      </Grid>
                    }
                  </VisibilitySensor>
                </div>
              </div>
            </div>
            <PlatformCard />
          </div>
        </div>

      </main >
    )
  }
}
export default Index