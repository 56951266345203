import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Link from '../../Link';

import { HeaderMock } from '../../../mock/RealHeaderMock';
import { AboutUsMock } from '../../../mock/AboutUsMock';
import Button from '../../Button';

window.prevHistory = {
  pathname: '',
  hash: '',
  key: '',
};

function isIE() {
  if (typeof window !== 'undefined') {
    var ua = window.navigator.userAgent;
    var msie = /MSIE|Edge|Trident\//g.test(ua);
    // console.info('iE:', (msie > 0 || !!window.MSInputMethodContext || !!document.documentMode))
    return msie;
  } else {
    return false;
  }
}

const Header = (props) => {
  const $html = document.getElementsByTagName('html')[0];
  const $body = document.body;
  const history = useHistory();
  const location = useLocation();
  const [greeting, setGreeting] = React.useState('早安！');
  const [mock, setMock] = React.useState(AboutUsMock);
  const isEBU = location.pathname.indexOf('ebu') > -1;
  const [headerType, setHeaderType] = React.useState('corp');
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [catogory, setCatogory] = React.useState(0);
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
  const [hasNotification] = React.useState(
    ((location.pathname === '/' || location.pathname.indexOf('/index') > -1) && window.innerWidth > 768) ||
    ((location.pathname === '/' || location.pathname.indexOf('/index') > -1) && window.innerWidth < 768)
  );
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [currentMenu, setCurrentMenu] = React.useState(0);
  // record ref
  const $header = React.createRef();
  const $root = document.getElementById('root');

  useEffect(() => {
    const dt = new Date().getHours();

    setGreeting(dt > 19 ? '晚安！' : dt > 12 ? '午安！' : '早安！');

    if (typeof window !== 'undefined') {
      window.isIE = isIE();
      if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'en') {
        window.$isEn = true;
        document.body.classList.add('is-en');
      }

      if (window.prevHistory.pathname === '') {
        window.prevHistory = {
          pathname: window.location.pathname,
          hash: window.location.hash,
          origin: window.location.origin,
          search: window.location.search,
        };
      }

      // detectHeader(window.location.pathname);

      const $main = document.getElementsByTagName('main')[1] || document.getElementsByTagName('main')[0];

      // console.log($main, $main.getElementsByTagName('header'))
      if ($main && $main.getElementsByTagName('header').length) {
        if (window.isIE) {
          if ($root.children[0].classList.contains('fui-header')) {
            $root.children[0].removeElement();
          }
          $root.prependHtml($header.current);
        } else {
          if ($root.children[0].classList.contains('fui-header')) {
            $root.children[0].remove();
          }
          $root.prepend($header.current);
        }
      }

      is_high_resolution_screen();
    }

    return function cleanup() {
      unlisten();
    };
  }, [is_high_resolution_screen, $root, $header, unlisten, detectHeader]);


  const is_high_resolution_screen = () => {
    // console.log(`window.devicePixelRatio > 1`, window.devicePixelRatio > 1);
    return window.devicePixelRatio > 1 ? $html.classList.add('is-retina') : null;
  };

  const unlisten = history.listen((location) => {
    if (menuOpen) {
      setMenuOpen(false);
      setSideMenuOpen(false);
    }
    if (searchOpen) setSearchOpen(false);
    detectHeader(location.pathname);
  });

  const detectHeader = useCallback((path) => {
    switch (true) {
      case path.indexOf('serviceCoverageController') > -1:
        setHeaderType('help-center');
        break;
      case path.indexOf('/help-center') > -1:
        setHeaderType('help-center');
        setMock(AboutUsMock);
        break;
      case path.indexOf('/corp') > -1:
      case path.indexOf('/') > -1:
        setHeaderType('corp');
        setMock(AboutUsMock);
        break;
      default:
        setMock(HeaderMock);
        setHeaderType('default');
        break;
    }
  });

  const openSearch = () => {
    setSearchOpen(true);
    $html.classList.add('sidebar-open');
  };

  const closeSearch = () => {
    setSearchOpen(false);
    $html.classList.remove('sidebar-open');
  };

  const toggleMenu = (index) => {
    if (index !== currentMenu) {
      setSideMenuOpen(true);
      setCurrentMenu(index);
    } else {
      setSideMenuOpen(!sideMenuOpen);
    }
  };

  if (isEBU) document.body.classList.add('is-ebu');

  const isExternalLink = (link) => {
    return !!link && (/\/\//.test(link) || link.indexOf('.pdf') > -1 || /((tel:|mailto:))/.test(link));
  };

  const changeCategory = (menu, index) => {
    if (!!menu.content) {
      setCatogory(index);
    } else {
      if (isExternalLink(menu.link)) window.location.href = menu.link;
      else history.push(menu.link);
    }
  };

  const openMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) $html.classList.add('sidebar-open');
    else $html.classList.remove('sidebar-open');
  };

  const changeIndex = (checked) => {
    $html.classList.add('fade-out');
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.location.href = checked ? mock.ebuLink : mock.cbuLink;
      }, 300);
    }
  };

  return (
    <header
      className={`fui-header is-corp ${menuOpen ? 'is-open' : ''} ${searchOpen ? 'is-search-open' : ''} is-fixed`}
      ref={$header}>
      <nav className='fui-navbar has-shadow is-spaced' role='navigation' aria-label='main navigation'>
        <div className='fui-container'>
          <div className='fui-navbar-menu'>
            <div className='navbar-left'>
              <Link to={mock.logoLink} id='brand' className='navbar-item brand'>
                <img src="/resources/images/aja-and-fet-logo.svg" alt='logo' />
              </Link>
            </div>
            <div className='navbar-left'>
              <button id='menu-trigger' className='menu-trigger' onClick={openMenu} tabIndex='0'>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className='fui-menu-container' role='menu' tabIndex='-1' aria-labelledby='menu-trigger'>
        <div className='fui-menu-header'>
          <div className='fui-container'>
            <div className='fui-navbar-menu'>
              <Link to={mock.logoLink} className='navbar-item brand'>
                {/* <img src={process.env.PUBLIC_URL + mock.logo} alt='' /> */}
              </Link>
              <div className='close-btn'>
                <button className='close' onClick={openMenu}>
                  <i className='icon-close'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='fui-menu-body'>
          <div className='fui-container'>
            <Grid container>
              <Grid item md={12} className='w-100'>
                <div className="d-md-flex item-wrapper">
                  <Link className={`item ${location.pathname === '/' ? 'active' : ''}`} to="/">
                    <div className="d-flex flex-align-center">
                      <img className='d-inline-block mr-1' src="resources/images/triangle-red.svg" alt="triangle" />
                      <p className="is-text-white m-0">專案目標</p>
                    </div>
                    <h6 className="is-text-white mb-0 mt-1">
                      不只是電信，<br />替未來生活提案
                    </h6>
                    <img className='bg-img' src="resources/images/menu-thumb-01@2x.jpg" alt="" />
                  </Link>
                  <Link className={`item ${location.pathname === '/Research-method' ? 'active' : ''}`} to="/Research-method">
                    <div className="d-flex flex-align-center">
                      <img className='d-inline-block mr-1' src="resources/images/triangle-red.svg" alt="triangle" />
                      <p className="is-text-white m-0">研究方法</p>
                    </div>
                    <h6 className="is-text-white mb-0 mt-1">
                      以顧客研究發現為起點，打造多單位協作的高效溝通
                    </h6>
                    <img className='bg-img' src="resources/images/menu-thumb-02@2x.jpg" alt="" />
                  </Link>
                  <Link className={`item ${location.pathname === '/Architrcture' ? 'active' : ''}`} to="/Architrcture">
                    <div className="d-flex flex-align-center">
                      <img className='d-inline-block mr-1' src="resources/images/triangle-red.svg" alt="triangle" />
                      <p className="is-text-white m-0">資訊架構</p>
                    </div>
                    <h6 className="is-text-white mb-0 mt-1">
                      從個人到商用，<br />聚焦需求快速分流
                    </h6>
                    <img className='bg-img' src="resources/images/menu-thumb-03@2x.jpg" alt="" />
                  </Link>
                  <Link className={`item ${location.pathname === '/Promotion' ? 'active' : ''}`} to="/Promotion">
                    <div className="d-flex flex-align-center">
                      <img className='d-inline-block mr-1' src="resources/images/triangle-red.svg" alt="triangle" />
                      <p className="is-text-white m-0">情境式行銷</p>
                    </div>
                    <h6 className="is-text-white mb-0 mt-1">
                      活絡集團資源與跨售，數位服務客製化讓廣告更有感
                    </h6>
                    <img className='bg-img' src="resources/images/menu-thumb-04@2x.jpg" alt="" />
                  </Link>
                  <Link className={`item mr-0 ${location.pathname === '/DesignSystem' ? 'active' : ''}`} to="/DesignSystem">
                    <div className="d-flex flex-align-center">
                      <img className='d-inline-block mr-1' src="resources/images/triangle-red.svg" alt="triangle" />
                      <p className="is-text-white m-0">識別系統</p>
                    </div>
                    <h6 className="is-text-white mb-0 mt-1">
                      打造品牌識別，<br />推動向前的設計主張
                    </h6>
                    <img className='bg-img' src="resources/images/menu-thumb-05@2x.png" alt="" />
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </header>
  )
};

export default Header;
