import React from 'react';
import {Parallax} from 'react-scroll-parallax';

const ArrowLeftWhite = (props) => {
    return (
        <Parallax className="animate-arrow-left" x={['-150%', '150%']}>
            {props.color!=='gray'?
            <svg xmlns="http://www.w3.org/2000/svg" width="403" height="600" fill="none" viewBox="0 0 403 600">
                <path fill="url(#paint0_linear)" fillOpacity=".2" d="M403 297.408L0 0v600l403-302.592z"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="403" x2="0" y1="293" y2="300" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BCC7D1"/>
                        <stop offset="1" stopColor="#E0E6EC" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>:
            
            <svg xmlns="http://www.w3.org/2000/svg" width="403" height="600" fill="none" viewBox="0 0 403 600">
                <path fill="url(#paint0_linear)" d="M403 297.408L0 0v600l403-302.592z"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="403" x2="0" y1="293" y2="300" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BCC7D1"/>
                        <stop offset="1" stopColor="#E0E6EC" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            }
        </Parallax>
    );
}

export default ArrowLeftWhite;